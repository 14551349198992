import { apiGetVideoGPS } from '@/api/index.js'
import { apiVideoSearch, apiVideoCount } from '@/api/v4'
import moment from 'moment'

const initialState = () => ({
  eventInfo: {}, // 事件資料
  searchLoading: false, // 搜尋中
  queryParams: {}, // videoList 搜尋條件
  isVideoFetched: false, // 是否已取得 video list
  total: 0, // video 總數
  videoList: [], // video list 影片從新到舊排列
  videoGpsList: [], // 紀錄 video GPS 位置資料 videoGpsList = [ [gpsList], [gpsList], ..... ]
  videoIndex: -1, // 回放 video index, 0是最新的影片，越大越舊
  videoTime: '', // 回放 video 時間
  videoPathMap: {}, // 紀錄 video 與其 GPS path 資料的對應
  videoFocusIndex: -1, // video focus index 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  isBackPlay: false, // 是否回放 for EventVideo 是否為影片模式
  playbackMode: 'event', // 回放模式 event / specific (特定時間)
  isDashboardBackPlay: false, // 是否回放 for Dashboard
  isShowEventVideoPanel: false, // 是否顯示事件影片視窗
  isShowVideoDownloadPanel: false, // 是否顯示影片下載視窗(可設定時間區間搜尋)
  isQueryByTime: false, // 下載視窗是否透過時間搜尋
  hlsVideoList: [], // 暫存 HLS video list
  playSpecificTime: '', // 播放特定時間, 用於回放特定時間的影片
  caller: 'history', // 辨別是 history or dashboard 在使用
  isEventVideoFetched: false, // 是否已取得 event video list
  eventVideoList: [], // 該事件的影片陣列
  eventVideoIndex: -1, // 該事件的影片index
  eventVideoTotal: 0, // 該事件的影片總數

  videoSearchStartTime: null, // 搜尋的開始時間
  videoSearchStopTime: null, // 搜尋的結束時間

  // 以下為搜尋用 for VideoDownload 元件
  queryVideoParams: {}, // 搜尋條件
  queryVideoList: [], // 搜尋的影片陣列
  queryVideoIndex: -1, // 搜尋的影片index
  queryVideoTotal: 0, // 搜尋的影片總數
  queryVideoCurrentPage: 1, // 搜尋的影片目前頁數
})

const state = initialState()
const getters = {
  wholeVideoPath(state) {
    if (state.videoGpsList.length === 0 || state.videoIndex === -1) return []

    const pathIdx = state.videoPathMap[state.videoIndex]
    if (!state.videoGpsList[pathIdx] || state.videoGpsList[pathIdx].length === 0) return []

    return state.videoGpsList[pathIdx].map((item) => ({
      lock: item.lock,
      timestamp: item.timestamp,
      lat: item.latitude,
      lng: item.longitude,
    }))
  },
  videoPath(state, getters) {
    if (getters.wholeVideoPath.length === 0) return []

    return getters.wholeVideoPath
      .filter(item => new Date(state.videoTime).getTime() >= new Date(item.timestamp).getTime())
  },
}
const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateEventInfo(state, info) {
    state.eventInfo = info
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateQueryParams(state, queryParams) {
    state.queryParams = queryParams
  },
  updateIsVideoFetched(state, value) {
    state.isVideoFetched = value
  },
  updateTotal(state, total) {
    state.total = total
  },
  updateVideoList(state, videoList) {
    state.videoList = videoList
  },
  addVideoList(state, list) {
    state.videoList.push(...list)
  },
  updateVideoGpsList(state, list) {
    // gpsList 根據timestamp 由舊到新進行排序
    list.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    state.videoGpsList = list
  },
  addVideoGpsList(state, list) {
    // videoGpsList = [ [gpsList], [gpsList], ..... ]
    state.videoGpsList.push(list)
  },
  updateVideoIndex(state, videoIndex) {
    state.videoIndex = videoIndex
  },
  updateVideoTime(state, videoTime) {
    state.videoTime = videoTime
  },
  updateVideoPathMap(state, videoPathMap) {
    state.videoPathMap = videoPathMap
  },
  updateVideoFocusIndex(state, videoFocusIndex) {
    state.videoFocusIndex = videoFocusIndex
  },
  updateIsBackPlay(state, value) {
    state.isBackPlay = value
  },
  updatePlaybackMode(state, mode) {
    state.playbackMode = mode
  },
  updateIsDashboardBackPlay(state, value) {
    state.isDashboardBackPlay = value
  },
  updateIsShowEventVideoPanel(state, value) {
    state.isShowEventVideoPanel = value
  },
  updateIsShowVideoDownloadPanel(state, value) {
    state.isShowVideoDownloadPanel = value
  },
  updateIsQueryByTime(state, value) {
    state.isQueryByTime = value
  },
  addHlsVideoList(state, list) {
    state.hlsVideoList.push(...list)
  },
  updateHlsVideoList(state, list) {
    state.hlsVideoList = list
  },
  updatePlaySpecificTime(state, value) {
    state.playSpecificTime = value
  },
  updateCaller(state, caller) {
    state.caller = caller
  },
  updateIsEventVideoFetched(state, value) {
    state.isEventVideoFetched = value
  },
  updateEventVideoList(state, list) {
    state.eventVideoList = list
  },
  addEventVideoList(state, list) {
    state.eventVideoList.push(...list)
  },
  updateEventVideoIndex(state, videoIndex) {
    state.eventVideoIndex = videoIndex
  },
  updateEventVideoTotal(state, total) {
    state.eventVideoTotal = total
  },
  updateVideoSearchStartStopTime(state, { startTime, stopTime }) {
    state.videoSearchStartTime = startTime
    state.videoSearchStopTime = stopTime
  },
  updateQueryVideoParams(state, queryParams) {
    state.queryVideoParams = queryParams
  },
  updateQueryVideoList(state, list) {
    state.queryVideoList = list
  },
  updateQueryVideoIndex(state, videoIndex) {
    state.queryVideoIndex = videoIndex
  },
  updateQueryVideoTotal(state, total) {
    state.queryVideoTotal = total
  },
  updateQueryVideoCurrentPage(state, page) {
    state.queryVideoCurrentPage = page
  },
}
const actions = {
  /**
   * 取得事件 video list, 事件發生起迄時間 ±3 分鐘的影片
   */
  async getEventVideoList({ commit, state }, payload) {
    if (state.isEventVideoFetched) return

    try {
      commit('updateSearchLoading', true)
      commit('updateEventVideoList', [])
      commit('updateEventVideoTotal', 0)

      const params = { postId: payload.postId }
      params.startTime = moment(payload.startTime).subtract(3, 'minutes').toISOString()
      params.stopTime = moment(payload.stopTime).add(3, 'minutes').toISOString()
      params.limit = 100
      params.desc = 1
      params.purpose = payload.purpose

      commit('updateQueryVideoParams', params)  // 紀錄搜尋條件

      // 若是歷史影片，該事件 = 他的事件影片
      if (payload.eventType === 'video') {
        commit('updateEventVideoList', [payload.event])
        commit('updateEventVideoTotal', 1)
        commit('updateIsEventVideoFetched', true)

        // queryVideoList 初始值為該事件的影片
        commit('updateQueryVideoList', state.eventVideoList.slice(0, 100))
        commit('updateQueryVideoTotal', state.eventVideoTotal)
        commit('updateQueryVideoCurrentPage', 1)

        return
      }

      let hasMoreData = true
      while (hasMoreData) {
        const res = await apiVideoSearch(params)
        commit('addEventVideoList', res.data.videoList)
        if (res.data.videoList.length < params.limit) {
          hasMoreData = false
        } else {
          params.beforeId = res.data.videoList[res.data.videoList.length - 1].id
        }
      }
      commit('updateEventVideoTotal', state.eventVideoList.length)
      commit('updateIsEventVideoFetched', true)

      // queryVideoList 初始值為該事件的影片
      commit('updateQueryVideoList', state.eventVideoList.slice(0, 100))
      commit('updateQueryVideoTotal', state.eventVideoTotal)
      commit('updateQueryVideoCurrentPage', 1)

    } catch (err) {
      commit('updateIsEventVideoFetched', false)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  /**
   * 取得時間軸的影片,事件起迄時間 ±2 小時 → 篩選事件影片(事件起迄時間 ±3 分鐘) 
   */
  async getVideoList({ commit, state, dispatch }, payload) {
    commit('updateQueryParams', payload)

    try {
      let hasMoreData = true
      const params = { ...payload }
      while (hasMoreData) {
        const res = await apiVideoSearch(params)
        commit('addVideoList', res.data.videoList)
        if (res.data.videoList.length < params.limit) {
          hasMoreData = false
        } else {
          params.beforeId = res.data.videoList[res.data.videoList.length - 1].id
        }
      }
      commit('updateTotal', state.videoList.length)
    } catch (err) {
      console.error(`[Store.video.getVideoList] `, err)
    }
  },
  filterEventVideos({ commit, state }) {
    if (state.eventInfo.eventType === 'video') {
      // 若是歷史影片，該事件資料即為他的事件影片
      commit('updateEventVideoList', [state.eventInfo.event])
      commit('updateEventVideoTotal', 1)
      commit('updateIsEventVideoFetched', true)
    } else {
      // 取得事件影片：從 videoList 中取得事件起迄時間 ±3 分鐘的影片 --> 同步設定 queryVideoList
      const startTime = moment(state.eventInfo.startTime).subtract(3, 'minutes')
      const stopTime = moment(state.eventInfo.stopTime).add(3, 'minutes')
      const eventVideos = state.videoList.filter(item => {
        const s = moment(item.startTime)
        const e = moment(item.stopTime)
        return (s.isSameOrAfter(startTime) && e.isSameOrBefore(stopTime))
          || (s.isSameOrBefore(stopTime) && e.isSameOrAfter(stopTime))
      })
      commit('updateEventVideoList', eventVideos)
      commit('updateEventVideoTotal', eventVideos.length)
      commit('updateIsEventVideoFetched', true)
    }

    // 設定可搜尋下載影片的初始資料
    const params = { postId: state.eventInfo.postId }
    params.startTime = moment(state.eventInfo.startTime).subtract(3, 'minutes').toISOString()
    params.stopTime = moment(state.eventInfo.stopTime).add(3, 'minutes').toISOString()
    params.limit = 100
    params.desc = 1
    params.purpose = state.eventInfo.purpose

    commit('updateQueryVideoParams', params)  // 紀錄搜尋條件
    commit('updateQueryVideoList', state.eventVideoList.slice(0, 100)) // queryVideoList 初始值為該事件的影片
    commit('updateQueryVideoTotal', state.eventVideoTotal)
    commit('updateQueryVideoCurrentPage', 1)
  },
  async getQueryVideoList({ commit }, payload) {
    try {
      commit('updateSearchLoading', true)
      commit('updateQueryVideoParams', payload)

      if (!payload.afterId && !payload.beforeId) {
        // 取得搜尋 video 總數
        const countRes = await apiVideoCount(payload)
        commit('updateQueryVideoTotal', countRes.data.total)
      }
      const res = await apiVideoSearch(payload)
      if (payload.desc === 0) {
        res.data.videoList.reverse() // 由舊到新，需反轉
      }
      commit('updateQueryVideoList', res.data.videoList)
      commit('updateQueryVideoIndex', -1)
    } catch (err) {
      commit('updateQueryVideoTotal', 0)
      commit('updateQueryVideoList', [])
      commit('updateQueryVideoCurrentPage', 1)
      commit('updateQueryVideoIndex', -1)
      console.error(`[Store.video.getQueryVideoList] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  async getVideoGpsList({ commit, state, dispatch }, payload) {
    if (state.isVideoFetched) return
    try {
      const params = { postId: payload.postId }
      params.startTime = moment(payload.startTime).subtract(120, 'minutes').toISOString()
      params.stopTime = moment(payload.stopTime).add(120, 'minutes').toISOString()
      params.limit = 100
      params.desc = 1
      params.purpose = payload.purpose

      const startPlayTime = payload.startTime // 記錄開始播放時間

      // 紀錄時間軸影片搜尋時間起訖
      commit('updateVideoSearchStartStopTime', { startTime: params.startTime, stopTime: params.stopTime })

      commit('updateSearchLoading', true)
      await dispatch('resetVideoGpsPath')
      await dispatch('getVideoList', params)

      // 因為關檔異常會殘留異常的HLS video，所以要過濾掉
      const temp = state.videoList.filter((item) => item.type === 'HLS')
      const errHlsIds = []
      temp.forEach(item => {
        // 計算影片開始時間距離現在時間的秒數，若超過5分鐘(關檔時間)則移除
        const diff = moment().diff(moment(item.startTime), 'seconds')
        if (diff > 300) {
          errHlsIds.push(item.id)
        }
      })
      const video = state.videoList.filter((item) => errHlsIds.indexOf(item.id) === -1)
      commit('updateVideoList', video)
      commit('updateTotal', video.length)
      commit('updateIsVideoFetched', true)
      if (!state.isEventVideoFetched) dispatch('filterEventVideos') // 從 videoList 過濾事件影片
      dispatch('setVideoIndex', startPlayTime) // 取得該時間點的影片index
      commit('updateSearchLoading', false) // 停止顯示 Loading，開始播放影片

      if (state.caller !== initialState().caller) return

      // 取得 event video id
      const eventVideoIds = state.eventVideoList.map(item => item.id)
      const eventVideoLen = eventVideoIds.length
      const idStart = (eventVideoLen === 0) ?
        -1 : state.videoList.findIndex(item => item.id === eventVideoIds[0])
      const idEnd = (eventVideoLen === 0) ?
        -1 : state.videoList.findIndex(item => item.id === eventVideoIds[eventVideoLen - 1])

      const videoGpsMap = {}
      for (let i = 0; i < idStart; i++) {
        const res = await apiGetVideoGPS(state.videoList[i].id)
        videoGpsMap[i] = i
        if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
          commit('addVideoGpsList', res.data.gpsList)
        else
          commit('addVideoGpsList', [])
      }

      if (idStart !== -1 && idEnd !== -1) {
        let gpsList = [] // 紀錄所有 event videos gps list
        for (let j = 0; j < eventVideoLen; j++) {
          const res = await apiGetVideoGPS(eventVideoIds[j])
          videoGpsMap[idStart + j] = idStart
          if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
            gpsList = [...gpsList, ...res.data.gpsList]
        }
        // 依timestamp排序, 由舊到新
        gpsList.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
        commit('addVideoGpsList', gpsList)
      }

      let pt = idStart
      for (let k = idEnd + 1; k < state.videoList.length; k++) {
        const res = await apiGetVideoGPS(state.videoList[k].id)
        videoGpsMap[k] = ++pt
        if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
          commit('addVideoGpsList', res.data.gpsList)
        else
          commit('addVideoGpsList', [])
      }
      commit('updateVideoPathMap', videoGpsMap)

      // 在取得GPS資料後，再設定回放影片(若先更新，會導致回放先前播放影片，再跳至新影片)
      // 取得該時間點的影片index
      // dispatch('setVideoIndex', startPlayTime)
    } catch (err) {
      commit('updateIsVideoFetched', false)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  // 取得該時間點的影片index
  setVideoIndex({ commit, state }, time) {
    const checkTime = moment(moment(time).toISOString())
    let index = state.videoList.findIndex(item => {
      let s = moment(item.startTime)
      let e = moment(item.stopTime)
      return checkTime.isBetween(s, e, null, '[)') // [) 大於等於, 小於
    })

    commit('updateVideoIndex', index)
  },
  setClosedVideoIndex({ commit, state }, time) {
    const checkTime = moment(new Date(time).toISOString())
    // 需考慮某個時間點可能不在影片時間範圍內，需找出最接近的影片
    let index = -1
    for (let i = state.videoList.length - 1; i > 0; i--) {
      let start1 = moment(state.videoList[i].startTime)
      let start2 = moment(state.videoList[i - 1].startTime)
      if (checkTime.isBetween(start1, start2, null, '[]')) { // [) 大於等於, 小於等於
        index = i
        break
      }
    }

    if (index === -1) {
      // 找出最接近的影片, 利用checkTime 與每段影片時間差值最小的index
      // 若差值小於 30 秒，則設定該影片為回放影片
      const minThreshold = 30 // 30秒
      const diffList = state.videoList.map(item => Math.abs(moment(item.startTime).diff(checkTime, 'seconds')))
      const min = diffList.indexOf(Math.min(...diffList))
      index = diffList[min] <= minThreshold ? min : -1
    }
    commit('updateVideoIndex', index)
  },
  resetVideo({ dispatch }) {
    dispatch('resetEventVideo')
    dispatch('resetVideoGpsPath')
  },
  resetEventVideo({ commit }) {
    commit('updateIsEventVideoFetched', false)
    commit('updateEventVideoIndex', -1)
    commit('updateEventVideoTotal', 0)
    commit('updateEventVideoList', [])
  },
  resetVideoGpsPath({ commit }) {
    commit('updateIsVideoFetched', false)
    commit('updateVideoIndex', -1)
    commit('updateVideoTime', '')
    commit('updateTotal', 0)
    commit('updateVideoList', [])
    commit('updateHlsVideoList', [])
    commit('updateVideoGpsList', [])
    commit('updateVideoPathMap', {})
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}