// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const ja = {
  /* project */
  system_name: 'Mobile AI Video Platform',
  system_language: '言語',
  system_timezone: 'タイムゾーン',
  system_default: 'システムデフォルト',

  /* global */
  yes: 'はい',
  no: 'いいえ',
  cancel: 'キャンセル',
  ok: '確定',
  save: '保存',
  close: '閉じる',
  delete: '削除',
  confirm: '確認',
  finish: '完了',
  error: 'エラー',
  edit: '編集',
  view: '表示',
  none: 'なし',
  custom: 'カスタム',
  total: '合計',
  loading: '読み込み中',
  selected: '選択した',
  search: '検索',
  pre: '戻る',
  next: '次へ',
  stop: '中止',
  unknown: '知らない',
  tag: 'マーク',
  license_plate_example: '品川,599,さ,1234',
  on: 'オン',
  off: 'オフ',
  device: 'デバイス',
  vehicle: '車両',
  mission: 'ミッション',
  name: '名前',
  attribute: '属性',
  file: 'ファイル',
  link: 'リンク',
  up_time: '起動時間',
  update_time: '更新時間',
  all: '全て',
  adv_search: '詳細検索',
  search_result: '結果：{count}',
  new_tag_hint: '新しいタグ',
  event: 'イベント',
  decibel_meter: 'デシベルメーター',
  hint: 'ヒント',
  collapse: 'Collapse',
  expand: 'Expand',
  search_fail_title: '検索失敗',
  no_subscription: '未登記',
  delete_data: 'データの削除',
  delete_data_confirm: '{count} 個のデータを削除してもよろしいですか?',
  age: '{age}才',
  show_all: 'すべて表示',
  upload: 'アップロード',
  uploading: 'アップロード中...',
  success: '成功しました',
  fail: '失敗した',
  warn: '警告',
  check: 'チェック',
  create: '作成する',
  data_processing: '進行中...',
  data_loading: '読み込み中...',
  enable: '有効にする',
  enabled: '有效',
  disabled: '無效',
  search_placehold: '選んでください',
  nothing_modified: '何も変更されていません',
  no_data: 'データなし',
  select_all: '全選択',
  action: 'アクション',
  required: '必須',
  hub_reset: 'ハブのリセット',
  reboot: 'デバイスの再起動',
  restart: 'リブート',
  version: 'バージョン',
  nothing_edit: '何も編集しない',
  update: '更新',
  status: '状態',
  valid: '有効',
  invalid: '無効',
  format_invalid: 'フォーマットが無効です',
  empty_file: '{file} は空のファイルです。',
  error_code: 'エラーコード：{code}',
  map: '地図',
  satellite: '衛星',

  /* alarm */
  data_error: 'データエラー',

  /* language */
  lang_zh: '正體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: '日',
  mon: '月',
  tue: '火',
  wed: '水',
  thu: '木',
  fri: '金',
  sat: '土',
  month: '月',
  day: '日',
  one_day: '日',
  n_day: '{day} 日',
  hour: '時間',
  n_hour: '{hr} 時間',
  min: '分',
  n_min: '{min} 分',
  one_min: '分',
  sec: '秒',
  n_sec: '{sec} 秒',
  month_ago: 'ヶ月前',
  day_ago: '日前',
  hour_ago: '時間前',
  min_ago: '分前',
  sec_ago: '秒前',
  one_min_later: '分後',
  min_later: '分後',
  today: '今日',
  yesterday: '昨日',
  last_three_days: '過去3日',
  // last_week: 'この1週間',
  last_week: '過去7日間',
  this_month: '今月',
  last_month: '過去の月',
  last_month2: '先月',
  last_three_months: '過去3か月',
  half_year_ago: '過去6か月',
  one_year_ago: '過去1年',
  custom_time: 'カスタム時間',
  date_picker_shortcut_title: '素早く選択',
  parameter: 'パラメータ',

  /* login */
  login_id: 'アカウント',
  login_pw: 'パスワード',
  login_hint: 'アカウントとパスワードを入力してください',
  login_account_password_error: 'アカウントまたはパスワードが正しくありません',
  login_permission_hint: 'このアカウントにはこのシステムを使用する権限がありません。管理者に連絡してください。',
  login_npa_hint: 'システム管理者に連絡してください。',
  login_clean_cache_head: '扱うデータ',
  login_clean_cache_msg: 'ブラウザのキャッシュ データをクリアしてもよろしいですか?',
  login_clean_cache_btn_tooltip: 'キャッシュの消去',

  /* Api */
  api_400: '必要な情報が不足しています。',
  api_401: `間違ったユーザー名またはパスワード。`,
  api_401_login: `間違ったユーザー名またはパスワード。`,
  api_401_account_disabled: 'アカウントは無効になっています。',
  api_401_account_suspended: 'アカウントはロックされています。システム管理者にお問い合わせください。',
  api_403: `権限がありません`,
  api_403_duplicate_password: 'このパスワードは最近3回使用されました。',
  // api_403_certificate_key_pair_not_accepted: '証明書とキーペアは受け入れられません',
  api_403_certbot: `権限がありません（サーバー設定を確認してください）`,
  api_404: `資源が提供されていません。`,
  api_404_login: `間違ったユーザー名またはパスワード。`,
  api_404_default_setting: 'デフォルト設定が見つかりません',
  api_404_user_setting: 'ユーザー設定が見つかりません',
  api_406: `受け付けできません`,
  api_500: `内部サーバーエラー`,
  api_500_fr_duplicate_feature: '同じ特徴がすでに存在します',
  api_error: `発生したエラーは予期せぬものです。システム管理者に連絡してください。`,
  api_econnaborted: 'サーバー接続が失われました',
  api_timeout: 'リクエストはタイムアウトしました',
  api_get_title: '情報を入手する',
  api_network_error: 'ネットワークエラー',

  /* left bar */
  left_bar_dashboard: 'ダッシュボード',
  left_bar_history: 'イベント記録',
  // left_bar_subscription: '車號管理',
  left_bar_subscription: '照合データベース',
  left_bar_recognition: '照合データベース',
  left_bar_lpr_recognition: 'ナンバープレート管理',
  left_bar_fr_recognition: 'プロファイル管理',
  left_bar_account_management: 'アカウントと権限',
  left_bar_system_setting: 'システムとサービス',
  left_bar_log: 'ログ',
  left_bar_download_app: 'ダウンロードセンター',
  left_bar_export_center: 'エクスポート センター',
  left_bar_account: 'アカウント',
  left_bar_call: '音声通話',
  left_bar_joined_call: '通話に参加',
  left_bar_left_call: '通話に離席',
  left_bar_end_call_before: '現在の通話を終了してから新しい通話を開始してください',
  left_bar_cant_call_myself: '自分への通話ができません',
  left_bar_15_seconds_hangup: '15秒以内に応答がない場合、自動的に電話を切ります',
  left_bar_failed_sdp_speak: 'SDP情報の交換または音声通知の送信に失敗しました。通話は切断されます。',
  left_bar_another_webpage_call: '別の Web ページで通話を開始しました。通話を再開するためには、ページを閉じる必要があります',
  left_bar_other_has_hungup: '相手が電話を切りました',
  left_ber_userinfo_change_password: 'パスワード変更',
  left_bar_userinfo_username_placeholder: 'アカウント名前({max}文字以内)',
  left_bar_userinfo_email_placeholder: 'Eメール',
  left_bar_userinfo_phone_placeholder: '電話番号',
  left_ber_userinfo_change_password_sso_hint: 'SSO アカウントはパスワードを変更できません',
  left_bar_userinfo_contact_us: 'お問い合わせ',
  left_bar_userinfo_line_official_account: 'Bovia ライン公式アカウント',
  left_bar_userinfo_compatibility_title: 'クライアント環境の検出',
  left_bar_userinfo_compatibility_codec: '動画テスト',

  /* top bar */
  top_bar_video_fill: '映像滿夕',
  top_bar_map_class: '地図',
  top_bar_keep_login: 'ログインを維持する',
  top_bar_video_class: '画像',
  top_bar_online_devices: 'オンライン設備',
  top_bar_show_full_title: 'タイトル',
  top_bar_show_account_id: '口座番号',
  top_bar_show_recog_frame: 'ライブ認識フレーム',
  top_bar_calling_volume_off: 'During call, volume muted.',
  top_bar_calling_volume_reverse: 'The call ended and the volume has been restored.',

  /* 下載APP */
  download_notify_app: '通知アプリ',
  download_info: 'お手持ちの携帯電話でQRコードを読み取り、通知アプリをダウンロードしてください',
  download_android: 'アンドロイド',
  download_ios: 'iOS',
  download_manual: 'マニュアル',
  download_manual_fn: 'マニュアル.pdf',
  download_tool: 'ツール',
  download_search_placeholder: '検索',
  download_name: '名称',
  download_version: 'バージョン',
  download_update_time: 'アップデート時間',
  download_size: 'ファイルサイズ',
  download_os: 'オーエス',
  download: 'ダウンロード',
  download_no_info: 'データなし',

  /* 匯出中心 */
  export_fail: 'エクスポートできません',
  export_fail_hint: '取引件数が {count} 件を超えています。検索範囲を絞ってください。',
  export_category: 'カテゴリー',
  export_filename: 'ファイル名前',
  export_expiration_time: '有効期限',
  export_file_size: 'ファイルサイズ',
  export_status_download: 'ダウンロード',
  export_status_fail: 'エクスポートに失敗しました',
  export_status_exporting: 'エクスポート中',
  export_status_deleting: '削除中',
  export_warning_title: 'データのエクスポートができません',
  export_warning_content: 'エクスポートセンターは5つのレコード上限に達しました。古いデータを削除してください',
  export_warning_filename: 'ファイル名は{count}文字を超えることができません',
  export_result: '結果のエクスポート',
  export_custom_filename: 'ファイル名',
  export_license_plate_picture: 'ナンバープレート画像',
  export_snapshot: 'スナップショット',
  export_license_plate_picture_embedded: 'レポートに埋め込まれたナンバープレートの写真',
  export_photo_warning: 'エントリ数が {count} を超えているため、画像をエクスポートできません。画像をエクスポートする場合は、検索範囲を狭めてください。',
  export_search_criteria: '検索条件',
  export_plate_number: '車番号',
  export_time_interval: '時間間隔',
  export_event_category: 'イベントカテゴリー',
  // export_mission: 'ミッション',
  export_finished_condition: '完成状態',
  export_category_basic: '通常検索',
  export_category_advanced: '詳細検索',
  export_category_performance: 'Performance Search',
  export_category_audit: 'Audit Search',
  export_basic: 'Basic',
  export_adv: 'Adv',
  export_lpr_default_purpose: '業務管理',

  /* 二因子認證-2FA */
  twoFactorAuth_title: '二段階認証',
  twoFactorAuth_installApp: '認証アプリを利用ください',
  twoFactorAuth_installApp_desc1: '保護を強化するため、Google AuthenticatorやDuo Mobileなどのアプリケーションを使用して認証コードを生成してください。',
  twoFactorAuth_installApp_desc2: '認証アプリをまだインストールしていない場合、先にインストールしてから次に進んでください。',
  twoFactorAuth_scanQrCode: 'サードパーティ認証アプリを経由して設定してください',
  twoFactorAuth_scanQrCode_desc: 'Duo や Google Authenticatorなどの認証アプリで QR コードをスキャンしてください。',
  twoFactorAuth_inputCode: '認証コードを入力してください',
  twoFactorAuth_inputCode_login: '認証コードを入力してください',
  twoFactorAuth_inputCode_desc: '認証アプリから認証コードを入力してください。',
  twoFactorAuth_done: 'アクティベートしました。',
  twoFactorAuth_done_desc: 'このアカウントの二段階認証はアクティベートしました',
  twoFactorAuth_enabled_pass: '正常にアクティベートしました',
  twoFactorAuth_enabled_fail: 'アクティベート失敗しました',
  twoFactorAuth_disabled_pass: '無効になりました',
  twoFactorAuth_disabled_fail: '無効化できませんでした',
  twoFactorAuth_validate_pass: 'Successed to validate',
  twoFactorAuth_validate_fail: 'Failed to validate',
  twoFactorAuth_error_getQrCode: 'QRコード取得に失敗しました',
  twoFactorAuth_error_inputNumber: '数字を入力してください',
  twoFactorAuth_error_fullCode: '完全な認証コードを入力してください',

  /* 版本資訊 */
  version_info_header: 'バージョン情報',
  version_info_web: 'Web',
  version_info_version: 'バージョン',
  version_info_core: 'サーバー',
  version_info_version_range: 'バージョン範囲',
  version_info_update_time: '更新時刻',

  /* account */
  account_title: 'アカウント',
  account_name: '名前',
  account_role: '役割',
  account_change_password: 'パスワード変更',
  account_device_change_password: '認証コード変更',
  account_disable_2fa: '二段階認証を取り消す',
  account_id_tip: '{min}-{max} 文字を入力してください。文字、数字、または特殊記号が使用できます',
  account_id_length_error: 'アカウント文字列の長さは {min} ～ {max} 文字である必要があります',
  account_id_char_error: 'アカウントでは、英字、数字、大文字、小文字、特殊文字のみを使用できます',
  account_edit_password: 'パスワード変更',
  account_device_edit_password: '認証コード変更',
  account_edit_password_tip1: '1. 長さは {min} ～ {max} 文字である必要があります',
  account_edit_password_tip2: '2. 特殊文字を入力してください。',
  account_pw_tip: '英文字、数字、特殊記号を含む {min} ～ {max} 文字を入力してください',
  account_new_pw: '新しいパスワード',
  account_device_new_pw: '新しい認証コード',
  account_reenter_pw: '新しいパスワードを再入力してください',
  account_device_reenter_pw: '新しい認証コードを再入力してください',
  account_pw_length_error: '長さは {min} ～ {max} 文字である必要があります',
  account_pw_char_error: 'パスワードには、英字、数字、大文字、小文字、特殊文字のみを使用できます',
  account_pw_special_char_error: 'パスワードには特殊文字を 1 つ含める必要があります',
  account_pw_error: '2つのパスワードが一致しませんので、ご確認ください',
  account_device_pw_error: '2つの認証コードが一致しませんので、ご確認ください',
  account_pw_null: '空値は使用できません',
  account_pw_success: '編集に正常する',
  account_pw_failed: '編集に失敗する',
  account_video_title_tip: '特殊な記号の使用は避けてください',
  account_2fa: '二段階認証',
  account_logout: 'ログアウト',
  account_logout_hint: 'このページを離れますか？',
  account_delete_confirm: '削除の確認？',
  account_notify_edit_title: 'アカウント情報の編集',
  account_notify_edit_success: 'アカウント情報を変更しました',
  account_notify_edit_failed: 'アカウント情報の変更でエラーが発生しました',
  account_search_role: '役割の検索',
  account_add_role: '役割の追加',
  account_edit_role: '役割の編集',
  account_copy_role: '役割のコピー',
  account_delete_role: '役割の削除',
  account_add: 'アカウントの追加',
  account_add_success: 'アカウントを追加しました',
  account_add_failed: 'アカウントの追加でエラーが発生しました',
  account_add_failed_account_exist: 'This account already exists. Please fill it in again.',
  account_required: '<span class="required">*</span> 入力必要',
  account_detail_group_accound: 'Group and Account',
  account_detail_group: 'グループ',
  account_detail_id: 'アカウント',
  account_detail_password: 'パスワード',
  account_device_detail_password: '認証コード',
  account_required_info: '入力必要',
  account_viewing_quota: '視聴割り当て',
  account_multi_select: 'アカウントを複数選択する',
  account_cancel_multi_select: '複数選択モードを取り消し',
  account_selected: '選択したアカウント',
  account_batch_selected_account_only: 'Display Selected Account Only({count})',
  account_batch_edit_items: '項目の一括編集',
  account_add_items: '項目の追加',
  account_abnormal_account: '異常なアカウント',
  account_filter_result: '総数：{count}',
  account_batch_modified: 'が一括で変更されました',
  account_batch_n: '{pass_count} user(s) successfully and {fail_count} user(s) failed.',
  // account_n_success: '件成功しました',
  // account_n_failed: '件失敗しました',
  account_active: 'アクティベート中',
  account_inactive: '非アクティブ',
  account_not_activated: '無効',
  account_enabled: '使用開始',
  // account_disabled_until: '一時停止',
  account_disabled: '無効',
  account_suspend_view: '{time}まで一時停止されます',
  account_suspend_edit: '停止時間',
  account_status: 'スターテス',
  account_status_enabled: '有効',
  account_status_disable: '無効',
  account_status_locked: '停止許可',
  account_enable_confirm_title: 'アカウントを有効化',
  account_enable_confirm_msg: '{name} のアカウントを有効にしますか？',
  account_disable_confirm_title: 'アカウント無効化',
  account_disable_confirm_msg: '{name} のアカウントを無効にしますか？',
  account_group: 'グループ',
  account_id: 'アカウント',
  account_password: 'パスワード',
  account_device_password: '認証コード',
  account_confirm_password: 'パスワードの確認',
  account_device_confirm_password: '認証コードの確認',
  account_detail_name: '名前',
  account_all: '全て',
  account_public_title: 'こうかいじょうたい',
  account_public_all: '全て',
  account_public_0: 'プライベート',
  account_public_1: '公共',
  account_default_setting: '構成ソース',
  account_defaultSetting_1: 'システムのデフォルト',
  account_defaultSetting_0: 'デバイスのカスタマイズ',
  account_watermark: '透かし',
  account_watermark_0: '無効',
  account_watermark_1: '有効',
  account_detail_linkmode_title: 'リンク', // 勾稽
  account_linkmode_title: 'リンクモード', // 勾稽模式
  account_linkmode_all: '全て',
  account_linkmode_0: '無効',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - 強制的',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - 強制的',
  account_linkmode_group: 'グループ',
  account_linkmode_user: 'ユーザー',
  account_type: '口座の種類',
  account_kind_default: 'デフォルト',
  account_kind_sso: 'SSO',
  account_kind_device: 'デバイス',
  account_kind_user: 'ユーザー',
  account_kind_0: 'デフォルト',
  account_kind_1: 'SSO',
  account_kind_2: 'デバイス', // Device
  account_kind_3: 'ユーザー',
  account_label: 'アカウント名前',
  account_lang: '言語',
  account_tz: 'タイムゾーン',
  account_video_title: '動画タイトル',
  account_detail_property: '属性',
  account_lpr_keep: '事件キープ',
  account_video_keep: '動画キープ',
  account_keep_day: '保持時間',
  account_keep_day_not_keep: '保留しない',
  account_keep_day_by_disk: 'ディスク容量による',
  account_video_keep_day: '動画',
  account_fr_keep_day: '人物',
  account_lpr_keep_day: '車番号',
  account_or_keep_day: '物体',
  account_device_model: 'デバイスモデル',
  account_device_model_0: '他の',
  account_device_series_10: 'BoviCam 系列',
  account_device_series_100: 'AiCam 系列',
  account_device_series_200: 'Bodyworn Cam 系列',
  account_device_series_500: 'FaceArk 系列',
  account_device_series_1000: 'Android APP 系列',
  account_device_series_2000: 'iOS APP 系列',
  account_device_series_3000: 'Mac 系列',
  // account_device_series_4000: 'VirtualCam 系列',
  account_ip_source: 'IPソース',
  account_gps: 'GPS',
  account_from: 'アカウントソース',
  account_last_action: '最新のアクション',
  account_note: '備考',
  account_time: '時間',
  account_login_time: 'ログイン時間',
  // account_keep_day: 'データ',
  account_updated_time: '更新時間',
  account_updatedTime_title: '更新時間',
  account_register_time: '登録時間',
  account_locked: 'アカウントがロックされました',
  account_15min_later: '15分後',
  account_30min_later: '30分後',
  account_1hr_later: '１時間後',
  account_edit_title: 'アカウントの編集',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: 'アカウント情報の編集に失敗しました',
  account_edit_success: '{name} が正常に編集されました',
  account_lock_success: '{name} が正常にロックされました',
  account_lock_failed: 'ロックにエラーが発生しました',
  account_unlock: 'ロック解除済み',
  account_unlock_success: '{name} のロック解除しました',
  account_unlock_failed: 'ロック解除にエラーが発生しました',
  account_unlock_confirm_title: 'アカウントのロックを解除',
  account_unlock_confirm_msg: 'アカウント {name} をロック解除しますか？',
  account_source_sso: 'SSO',
  account_source_self: 'セルフクリエイト',
  account_forced_2fa: '2FA',
  account_forced2fa_0: 'オンデマンド',
  account_forced2fa_1: '強制',
  account_enabled_2fa: '二重認証が有効になっています',
  account_not_lived: '非ライブブロードキャスト設備',
  account_change_password_sso_hint: 'SSO アカウントはパスワードをリセットできません',
  account_forced_2fa_hint: 'まだ 2FA の使用を強制されていません',
  account_note_park_direction: '方向',
  account_note_park_direction_0: '入り口',
  account_note_park_direction_1: '出口',
  account_note_park_direction_2: 'なし',
  account_note_park_vehicle_type: '車種',
  account_note_park_vehicle_type_3: '自動車',
  account_note_park_vehicle_type_4: 'バイク',
  account_lastLogin_title: 'アイドル時間',
  account_lastLogin_all: 'すべて',
  account_lastLogin_30: '30日以上',
  account_lastLogin_90: '90日以上',
  account_lastLogin_180: '180日以上',

  /* role permission */
  // role_no_permission: '権限なし',
  // role_general: '一般',
  // role_advanced: '上級',
  // role_medium: '中級',
  // role_higher: '高等',
  // role_off: 'オフ',
  // role_on: 'オン',
  role_L0: 'L0：権限なし',
  role_new_name: '新しい役割(1)',
  role_topScope: '視線範囲',
  role_topScope_tooltip: '「表示範囲」としてグループを選択してください。',
  role_topScop_default: 'デフォルト',
  role_topScope_desc1: 'リソースアクセス範囲の設定を提供します',
  role_topScope_desc2: 'グループはツリー構造になっています「表示範囲」を設定することで、アクセス範囲を調整する最上位のグループノードが決定されます。現在の役割は、そのグループとすべての下位グループのリソースにアクセスできます。',
  role_topScope_desc3: '非公開アカウントから得られるリソースは、そのグループが属するグループに帰属する。',
  role_topScope_desc4: '特に指定がない場合、デフォルトのアクセス範囲は "表示範囲" に設定されます。デフォルト： アカウントの所属グループ。',
  role_topScope_L0: 'デフォルト：アカウントの所属するグループ。',
  role_topScope_L1: '指定：指定されたグループ。',
  role_audit: '使用履歴',
  role_audit_tooltip1: 'オフ：自分自身の記録のみ照会可能',
  role_audit_tooltip2: 'オン：他人の記録を照会することができます',
  role_audit_desc: 'アカウントの記録ログへのアクセスを提供します',
  role_audit_L0: 'L0：アカウント自身の利用記録を照会することのみ可能です。',
  role_audit_L1: 'L1：利用記録にアクセスできます。',
  role_mobilePermission: 'APP アクセス',
  role_boviliveMobile: 'BoviLive Mobile',
  role_boviliveMobile_desc: 'BoviLive モバイル アプリの制御機能。 ',
  role_boviliveMobile_L1: 'L1: 権限に従ってアプリを制御できます。 ',
  // 勤務派遣
  role_dashboardPermission: '勤務派遣',
  role_dashboard: '勤務派遣',
  role_dashboard_tooltip: '現場でのイベントや状況を集中管理する勤務派遣機能へのアクセスを提供します',
  role_dashboard_desc: '現場でのイベントや状況を集中管理する勤務派遣機能へのアクセスを提供します',
  role_dashboard_L0: 'L0：権限なし。勤務派遣機能ページにアクセスできません。',
  role_dashboard_L1: 'L1：勤務派遣にアクセスできます。',
  role_dashboard_L2: 'L2：公開された設備リストにアクセスできます。',
  // 影像管理
  role_videoPermission: '映像管理',
  role_liveView: 'ライブ映像',
  role_liveView_tooltip1: '一般： リアルタイム映像を視聴できます。',
  role_liveView_tooltip2: '上級：リアルタイム映像の視聴、タイトルの変更、PTZの制御などが可能です。',
  role_liveView_desc: 'ライブ映像、音声、GPSなどの情報へのアクセスを提供します。',
  role_liveView_L1: 'L1：ライブ映像にアクセスできます',
  role_liveView_L2: 'L2：公開ライブ映像にアクセスできます',
  role_video: '歴史映像',
  role_video_title: '履歴の映像検索・再生・ダウンロードを提供します',
  role_video_desc: '履歴の映像検索・再生・ダウンロードを提供します',
  role_video_L1: 'L1：履歴動画にアクセスできます',
  role_video_L2: 'L2：公開履歴動画にアクセスできます',
  role_videoManagement: '動画管理',
  role_videoManagement_tooltip: '履歴動画の管理機能を提供します',
  role_videoManagement_desc: '履歴動画の管理機能を提供します',
  role_videoManagement_L1: 'L1：履歴動画の説明文の修正、動画の保持・削除が可能です。',
  // 設備操控
  role_deviceCtrlPermission: 'デバイス操作',
  role_deviceCtrl: 'デバイス操作',
  role_deviceCtrl_tooltip: 'デバイスの設定と操作機能を提供します。',
  role_deviceCtrl_desc: 'デバイスの設定と操作機能を提供します。',
  role_deviceCtrl_L1: 'L1：動画のタイトルなど、デバイスに関する説明を変更できます。',
  role_deviceCtrl_L2: 'L2：カメラの関連設定、PTZ制御、品質設定などを調整できます。',
  role_deviceCtrl_L3: 'L3：デバイスの詳細設定を行うことができます。',
  role_deviceAISetting: '識別タスク',
  role_deviceAISetting_title: 'デバイスの識別タスク設定機能を提供します。',
  role_deviceAISetting_desc: 'デバイスの識別タスク設定機能を提供します。',
  role_deviceAISetting_L1: 'L1：識別タスクの設定にアクセスできます。',
  // 語音通話
  role_voiceCallPermission: '音声通話',
  role_voiceCall: 'ボイスチャット',
  role_voiceCall_tooltip: '音声通話機能が利用可能です。',
  role_voiceCall_desc: '1対1やグループ通話モードなど、音声インターホンサービスを提供します。',
  role_voiceCall_L1: 'L1：1対1やグループ通話など、音声インターホンにアクセスできます。',
  role_voiceCall_L2: 'L2：公開アドレス帳（ユーザー/デバイス）にアクセスできます。',
  // 車辨事件
  role_lprEventPermission: '車両識別事件',
  // role_lprDashboard: '儀表板功能',
  role_lprDashboard: 'リアルタイムナンバープレート事件',
  role_lprDashboard_tooltip1: '一般：ダッシュボードに比較/タスク/逮捕事件を表示できます。',
  role_lprDashboard_tooltip2: '詳細：ダッシュボードにすべての事件を表示できます。',
  role_lprDashboard_desc: 'リアルタイムのナンバープレート事件のアクセス機能を提供します',
  role_lprDashboard_L1: 'L1：車両情報、タスク、サブスクリプションなどを含む、車両番号のリアルタイムマッチング事件にアクセスできます。',
  role_lprDashboard_L2: 'L2：車両情報、タスク、サブスクリプションなどを含む、公開された車両番号のリアルタイムマッチング事件にアクセスできます。',
  role_lprDashboard_L3: 'L3：すべての車両番号のリアルタイム事件にアクセスできます。',
  // role_lprEventAccess: '事件查詢',
  role_lprEventAccess: '過去の車両番号識別事件 - 一般',
  role_lprEventAccess_tooltip1: '単一の車両番号をクエリできる一般的なイベント',
  role_lprEventAccess_desc: '車両ナンバープレートの履歴事件へのアクセス機能を提供します。',
  role_lprEventAccess_L1: 'L1：一般的な照会にアクセスできます（公開デバイスのみ）。',
  role_lprEventAdvancedAccess: '過去の車両番号識別事件 - 進階',
  role_lprEventAdvancedAccess_tooltip1: '高度な条件を使用して、事件を照会することができます',
  role_lprEventAdvancedAccess_desc: '車両ナンバープレートの履歴事件へのアクセス機能を提供します。',
  role_lprEventAdvancedAccess_L1: 'L1：高度な照会にアクセスできます（プライベートデバイスのみ）。',

  // 車號管理
  role_lprPermission: 'ナンバープレートの管理',
  // role_lprSummary: '一般查詢',
  role_lprSummary: 'ナンバープレートの照会',
  role_lprSummary_tooltip: '一つの車両情報を照会できます',
  role_lprSummary_desc: '車両情報/タスク/サブスクリプションなどを含む車両ナンバープレートのデータベース比較機能を提供します',
  role_lprSummary_L1: 'L1：グローバルなナンバープレートデータを照会できます。',
  role_lprSummary_L2: 'L2：グループ車両データを照会できます。',
  role_lprSubscription: 'サブスクリプション管理',
  role_lprSubscription_tooltip: 'サブスクリプション内容の新規追加/照会/削除できます。',
  role_lprSubscription_desc1: '車両ナンバープレートのサブスクリプション管理機能を提供します。',
  role_lprSubscription_desc2: 'サブスクリプションはグループデータです。',
  role_lprSubscription_desc3: 'フロントエンド機器がサブスクリプションのナンバープレートを認識した場合、アラートは発生しませんが、システムが受信者の携帯電話にインストールされた通知アプリに即時メッセージを送信します。',
  role_lprSubscription_L1: 'L1：サブスクリプションナンバープレートを管理できます。',
  role_lprMissionLv: 'タスク管理',
  role_lprMissionLv_tooltip1: '一般：一般タスク（捜索協力など）を設定できます。',
  role_lprMissionLv_tooltip2: '中級：中級以下のタスク（取調べ・捜索協力など）を設定できます。',
  role_lprMissionLv_tooltip3: '上級：上級以下のタスク（逮捕/取調べ/捜索協力など）を設定できます。',
  role_lprMissionLv_desc1: '車両ナンバープレートのタスク管理機能を提供します。',
  role_lprMissionLv_desc2: 'タスクはグローバルなデータです。',
  role_lprMissionLv_desc3: 'フロントエンド機器がタスクのナンバープレートを認識すると、直ちにアラートを発し、支援を要請するプッシュメッセージを表示します。受信者の携帯電話にインストールされた通知アプリにも即時メッセージが送信されます。',
  role_lprMissionLv_L1: 'L1：(捜索協力)タスクを管理できます。',
  role_lprMissionLv_L2: 'L2：(取調べ/捜索協力)タスクを管理できます。',
  role_lprMissionLv_L3: 'L3：(逮捕/取調べ/捜索協力)タスクを管理できます。',
  role_lprMission: 'タスクタイプ',
  role_lprMission_title: '（預留，目前未使用）',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：タスクタイプを管理できます。',
  // role_lprInfoLv: '車輛資訊管理',
  role_lprInfoLv: '車両情報',
  role_lprInfoLv_tooltip: '車両のマークと備考を管理できます。',
  role_lprInfoLv_desc1: '車両情報の管理機能を提供します。',
  role_lprInfoLv_desc2: '車両情報はグローバルなデータです。',
  role_lprInfoLv_L1: 'L1：グローバルな車両情報を管理できます。',
  // role_lprInfo: '標記列表管理',
  role_lprInfo: '車両のマーク',
  role_lprInfo_tooltip: 'マークリストを管理できます。',
  role_lprInfo_desc1: '車両のマークの管理機能を提供します。',
  role_lprInfo_desc2: '車両のマークはグローバルなデータであり、車両情報の分類の参照として使用されます。',
  role_lprInfo_desc3: '単一の車両情報には複数の車両マークを設定できます。',
  role_lprInfo_L1: 'L1：グローバルな車両マークを管理できます。',
  // role_lprAnnouncement: '公告管理',
  role_lprAnnouncement: 'プッシュ通知',
  role_lprAnnouncement_tooltip: '通知コンテンツを管理できます。',
  role_lprAnnouncement_desc1: 'プッシュ通知の管理機能を提供します。',
  role_lprAnnouncement_desc2: 'プッシュ通知はグローバルデータです。',
  role_lprAnnouncement_desc3: '通知メッセージはフロントエンドデバイスに送信され、音声で1回再生され、テキストメッセージがスク役割表示されます。',
  role_lprAnnouncement_L0: 'L0：プッシュ通知のお知らせを表示できます。',
  role_lprAnnouncement_L1: 'L1：グループのプッシュ通知のお知らせを管理できます。',
  role_lprAnnouncement_L2: 'L2：グローバルプッシュ通知を管理できます。',
  role_lprReport: 'パフォーマンスレポート',
  role_lprReport_tooltip: 'パフォーマンスレポートの管理機能を提供します。',
  role_lprReport_desc1: '- カスタマイズ: 選択された日付と機器に基づいて、カスタムのパフォーマンスレポートを生成します。',
  role_lprReport_desc2: '- 定期: 毎月、設定されたエクスポートデバイスのリストに基づいて、システムのパフォーマンスレポートを自動的に生成します。',
  role_lprReport_L1: 'L1：アクセス可能なカスタムパフォーマンスレポート。',
  role_lprReport_L2: 'L2：システムの定期的なパフォーマンスレポートおよびエクスポートデバイスの管理にアクセスできます。',
  // 物件辨識
  role_orEventPermission: '物件辨識',
  role_orDashboard: '即時物件事件',
  role_orDashboard_desc: '提供即時物件事件存取功能。',
  role_orDashboard_L1: 'L1：可存取物件即時事件。',
  role_orDashboard_L2: 'L2：可存取公開物件即時事件。',
  role_orEventAccess: '歷史物件事件',
  role_orEventAccess_desc: '提供歷史物件事件存取功能。',
  role_orEventAccess_L1: 'L1：可存取物件歷史事件。',
  // 人臉辨識
  role_frEventPermission: '顔認識',
  role_frDashboard: '実時間人物イベント',
  role_frDashboard_desc: '実時間人物イベントへのアクセス機能を提供します。',
  role_frDashboard_L1: 'L1：Can access real-time matched person events.',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: '履歴人物イベント',
  role_frEventAccess_desc: '履歴人物イベントへのアクセス機能を提供します。',
  role_frEventAccess_L1: 'L1：履歴人物のイベントにアクセスできます。',
  // 人物管理
  role_frPermission: '人物管理',
  role_frInfoLv: '人物情報',
  role_frInfoLv_desc1: '人物情報の管理機能を提供します。',
  role_frInfoLv_desc2: '人物情報はグローバルデータです。',
  role_frInfoLv_L1: 'L1：グローバルな人物情報を管理できます。',
  role_frInfoLv_L2: 'L2：プロファイルデータベースをエクスポートできます。',
  role_frInfo: '人物タグ',
  role_frInfo_desc1: '人物タグの管理機能を提供します。',
  role_frInfo_desc2: '人物タグはグローバルデータであり、車両情報の分類に使用されます。',
  role_frInfo_desc3: '単一の人物情報には、複数の人物タグを設定できます。',
  role_frInfo_L1: 'L1：グローバルな顔認識タグを管理できます。',
  // 帳號與授權
  role_accounrPermission: 'アカウントと承認',
  // role_account: '帳號管理',
  role_account: 'ユーザー',
  role_account_tooltip: 'ユーザーアカウント関連設定を管理できます。',
  role_account_desc1: 'ユーザーアカウントの管理機能を提供します。',
  role_account_desc2: 'ユーザーアカウントの確認が必要で、設定された役割権限に基づいて、リソースや機能へのアクセスレベルが決定されます。',
  role_account_L1: 'L1：ユーザーアカウントを表示できます。',
  role_account_L2: 'L2：ユーザーアカウントを管理できます。',
  role_device: 'デバイス',
  role_device_tooltip: 'デバイスアカウント関連の設定を管理できます。',
  role_device_desc1: 'デバイスアカウント管理機能を提供します。',
  role_device_desc2: 'デバイスアカウントは検証を通過し、構成された許可に基づいて提供できる機能とリソースの保持条件を決定します。',
  role_device_L1: 'L1：デバイスアカウントを表示できます。',
  role_device_L2: 'L2：デバイスアカウントを管理できます。',
  role_device_L3: 'L3：デバイスの認証を設定できます。',
  role_device_L4: 'L4：デバイスアカウントを追加できます。',
  // role_group: 'Group management',
  role_group: 'グループ',
  role_group_tooltip: 'グループ関連の設定を管理できます。',
  role_group_desc1: 'グループ管理機能を提供します。',
  role_group_desc2: 'グループはリソースの所属する基本単位であり、ユーザーやデバイスを構成でき、その派生リソース（履歴映像、事件、ログなど）はデフォルトでそのグループに帰属します。',
  role_group_desc3: 'グループは削除できません。',
  role_group_L1: 'L1：グループを表示できます。',
  role_group_L2: 'L2：グループを管理できます。',
  // role_roleManagement: 'Role management',
  role_roleManagement: '役割',
  role_roleManagement_tooltip: '役割を管理できます。',
  role_roleManagement_desc1: '役割管理機能を提供します',
  role_roleManagement_desc2: '『役割（Role）』は、アクセス範囲（Scope）や機能レベル（Level）を定義する権限の集合です。',
  role_roleManagement_desc3: 'ロール管理権限を持つオペレーターは、アクセススコープや権限レベルに対応するロールを作成または管理することができます。',
  role_roleManagement_desc4: 'ロールはグローバルスコープに属し、同じ名前を持つことはできません。',
  role_roleManagement_L1: 'L1：役割を表示できます。',
  role_roleManagement_L2: 'L2：役割を管理できます。',
  // 系統與服務
  role_systemAndServicePermission: 'システムとサービス',
  // role_system: '系統管理',
  role_system: 'システム設定',
  role_system_tooltip: 'システムパラメータを変更できます。',
  role_system_desc1: 'システム設定管理機能を提供します。',
  role_system_desc2: 'システムの設定やカスタマイズを調整するために使用されます。',
  role_system_L1: 'L1：一般的なシステム設定を管理できます。',
  role_system_L2: 'L2: 詳細なシステム設定を管理できます。(例: 顧客、機能、識別データベース、ロゴなどの管理)',

  role_systemDevice: 'デバイス',
  role_systemDevice_desc1: 'デバイス管理機能を提供します。',
  role_systemDevice_desc2: 'ステータスの表示、デバイス設定およびサービスの管理に使用します。',
  role_systemDevice_L1: 'L1：デバイス設定を表示できます。',
  role_systemDevice_L2: 'L2：デバイス設定を管理できます。',

  role_systemAibox: 'AiBox',
  role_systemAibox_desc1: 'AiBox 管理機能を提供します。',
  role_systemAibox_desc2: 'ステータスの表示、サービスの管理、タスクの識別に使用します。',
  role_systemAibox_L1: 'L1: 表示可能な AiBox。',
  role_systemAibox_L2: 'L2: 管理可能な AiBox。',

  role_systemServer: 'サーバー',
  role_systemServer_desc1: 'サーバー管理機能を提供します。',
  role_systemServer_desc2: 'ステータスの表示、サーバーとサービスの管理に使用します。',
  role_systemServer_L1: 'L1: サーバー ステータスを表示できます。',
  role_systemServer_L2: 'L2: 管理可能なサーバーです。',

  role_systemCertificate: '証明書',
  role_systemCertificate_desc1: '証明書管理機能を提供します。',
  role_systemCertificate_desc2: '証明書の表示と管理に使用します。',
  role_systemCertificate_L1: 'L1: 表示可能な証明書です。',
  role_systemCertificate_L2: 'L2: 管理可能な資格情報です。',

  /* Role Notify / Message */
  role_notify_add_success: `ロールが正常に追加されました`,
  role_notify_add_info: `ロールを追加しました：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `ロールを追加できませんでした`,
  role_notify_edit: '編集役割',
  role_notify_edit_success: '編集された役割',
  role_notify_delete_confirm: '<span>{name}</span> を削除しますか？', // '確定刪除 <span>{name}</span> ？',
  role_notify_delete_success_title: '役割が正常に削除されました。',
  role_notify_delete_success_msg: `役割を削除：<span style="${notify_style}">{name}</span>`, // `刪除角色：<span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: '名前の変更ができません',
  role_message_error_name_content: 'この名前は既に使用されています。別の名前を入力してください。',
  role_message_error_delete_title: '役割を削除できません',
  role_message_error_delete_prefix: '先に移行してください。',
  role_message_error_delete_suffix: '以下の全アカウントを他の役割に',
  role_message_error_delete_transfer: 'まずは<span>{name}</span> のすべてのユーザーを他の役割に移動してください', // '請先轉移 <span>{name}</span> 底下的所有使用者至其他角色。',

  /* 音效提示 */
  alarm_hint: 'ヒント',
  alarm_announcement: 'アナウンス',
  alarm_info: 'ブラウザーでの音声再生を許可しますか？',
  resolution_suggestion: `推奨される閲覧解像度:{resolution}`,
  // resolution_situation: `現在のブラウザ解像度/ピクセル比：{w} x {h} / {p}%`,
  resolution_situation: `現在使用している閲覧解像度（ピクセル比）：{resolution}（{p}%）`,

  /* Dashboard: user tree */
  tree_watch_list: 'ウォッチリスト',
  tree_device_list: 'デバイスリスト',
  tree_device_snapshot: 'ピクチャーモード',
  tree_device_hint: 'フォローしたいデバイスにチェックを入れてください',
  tree_device_select_all: 'すべて選択する',
  tree_search: 'グループ/機器を検索する',
  tree_online: 'オンライン',
  tree_online_all: 'オンライン/すべて',
  tree_selected: '選択済み',
  tree_select_group: 'このグループのみを選択',
  tree_change_name: '名前を変更する',
  tree_change_video_title: 'ビデオタイトルを変更する',
  tree_change_group_name: 'グループ名を変更する',
  tree_change_name_validate_info: `特殊記号 {chars} の使用は避けてください。`,
  tree_push_talk: 'グループ通話',
  tree_select_account: 'このアカウントのみを選択',
  tree_voice_call: '1対1の通話',
  tree_account_info: 'デバイス情報',
  tree_view_device: 'デバイスを表示する',
  tree_view_user: '檢視使用者',
  tree_view_account: '檢視帳號',
  tree_video_export: 'ビデオのエクスポート',
  tree_processing_info: '処理中...',
  tree_no_data: 'データなし',
  tree_contacts: '連絡先',
  tree_please_click: 'クリックしてください',
  tree_tip: 'フォローしたいデバイスにチェックを入れてください',
  tree_call_search: 'グループ/アカウントを検索',

  /* Dashboard: video download */
  video_start_time: '開始時間',
  video_end_time: '終了時間',
  video_type: 'ビデオタイプ',
  video_length: '動画の長さ',
  video_size: 'ビデオサイズ',
  video_date_placeholder: '開始と終了の時間を選択してください。',
  video_select_all: 'すべて選択する',
  video_unselect: 'キープをキャンセルする',
  video_keep_all: 'すべてをキープする',
  video_keep_select: 'チェックを入れたままにする',
  video_download_select: '選択したものをダウンロードする',
  video_delete_select: '選択したものを削除する',
  video_download: '動画をダウンロードする',
  video_play: 'ビデオの再生',
  video_keep: 'キープ',
  video_none_delete: '削除できるビデオはありません！',
  video_delete: '動画が削除されました！',
  video_none_select: '選択したデータはありません！',
  video_set_keep: 'ビデオが保持されました！',
  video_no_keep: 'ビデオの保持が解除されました！',
  video_delete_data: 'データを削除する',
  video_delete_checked: 'チェックした動画を削除してもよろしいですか?',
  video_selected: '選択したデータ',
  video_total_of: '合計',
  video_of_records: '件データ',
  video_unsupported_mime_codec: 'サポートされていないビデオ形式',

  /* Video Control Button */
  video_ctrlbtn_ptz: 'リモートカメラの制御',
  video_ctrlbtn_time_axis: '履歴動画',
  video_search_none: '動画が見つかりませんでした',

  /* Dashboard: event cards */
  event_tab_all: '全て',
  event_tab_match: '一致',
  event_tab_urgent: '緊急',
  event_tab_video: '動画',
  event_show_position: 'アカウントの位置を表示する',
  evnet_show_history: '関連する履歴記録を表示する',
  event_source: 'ソース',
  event_result: '一致した結果',
  event_note: '備考',
  event_recg_note: '認識ノート',
  event_emergency: '緊急事態',
  event_switch: 'すべてのイベントを表示',
  event_audio: 'マッチしたイベントの音声を再生',
  event_not_db: 'データベース外の事件',
  event_chasing: '囲み込み',
  event_sos: 'SOS',

  event_lpr: 'ナンバープレート',
  event_fr: '人物イベント',
  event_chased: '切り上げする',
  event_sos_title: 'SOS イベント',
  event_video: 'ビデオイベント',
  event_or: 'オブジェクトイベント',

  event_display: 'イベントの表示',
  display: '表示',
  sound: '音声',
  flash: '点滅',

  /* PTZ */
  ptz_move_speed: '移動スピード',
  ptz_zoom: 'ズーム',
  ptz_focus: 'フォーカス',
  ptz_preset: '座標リスト',
  ptz_preset_placeholder: '新しい座標',
  ptz_preset_setting_success: '座標設定が成功しました',
  ptz_preset_setting_fail: '座標設定に失敗しました',
  ptz_vertical_mirror: '垂直ミラー',
  ptz_horizontal_mirror: '水平ミラー',
  ptz_infrared: '赤外線',
  ptz_high_sensitivity: '高感度',
  ptz_tooltip_zoom_out: '縮小',
  ptz_tooltip_zoom_in: '拡大',
  ptz_tooltip_open: 'オープン',
  ptz_tooltip_close: 'クローズ',
  ptz_tooltip_auto: 'オート',
  ptz_tooltip_auto_focus: 'オートフォーカス',
  ptz_tooltip_near_focus: 'クローズフォーカス',
  ptz_tooltip_far_focus: 'ロングフォーカス',
  ptz_tooltip_gamepad: 'ゲームコントローラーを接続し、任意のボタンを押して有効化してください。',

  /* 影像 */
  image: '画像',

  /* 設備 */
  device_reboot: '再起動',
  device_reboot_confirm: 'デバイスの "{account}" を再起動してもよろしいですか?？',
  device_hub_reset: 'リセット',
  device_hub_reset_confirm: 'デバイスの "{account}" をリセットしてもよろしいですか？',

  /* History: Search Bar */
  search_tab_history: '通常検索', // 歷史事件
  search_tab_advanced_search: '詳細検索', // 進階搜尋
  search_cause: '事件の調査理由',
  search_cause_placehold: 'コンビニの駐車場に不審車両事件を調査',
  search_plate_number_required: '車番号',
  search_time_interval: '時間帯',
  search_select_date: '日付を選択する',
  search_select_time: '時間を選択する',
  search_time_placehold: '開始と終了の時間を選択してください。',
  search_hint: 'ヒント',
  search_hint_reason: '事件の理由を入力してください。',
  search_hint_reason_length: '事件の理由は30文字以内で入力してください',
  search_hint_fill_number: '「ナンバープレート」を入力してください',
  search_hint_fill_device: '「デバイス」を選択してください',
  search_hint_fill_time: '「時間帯」を選択してください。',
  search_hint_fill_operate_time: '「動作時間」を選択してください',
  search_hint_nodata: 'データが見つかりません',
  search_hint_no_plate_number: 'ナンバープレートを入力しない場合、最大検索間隔は {number} 日になります。',
  search_hint_max_date_range: '最大検索間隔は {number} 日になります',
  search_export: 'エクスポート',
  search_device: 'デバイス',
  search_device_required: 'デバイス            ',
  search_device_placehold: '選択してください',
  search_device_unit: 'デパートメント',
  search_plate_number: '車番号',
  search_car_type: '車両タイプ',
  search_car: '自動車',
  search_motocycle: 'バイク',
  search_event_category: '事件カテゴリー',
  search_please_choose: '選択してください',
  search_all: 'すべて',
  search_subscription: 'サブスクリプション',
  search_mission: 'ミッション',
  search_mark: 'マーキング',
  search_round_up: '逮捕',
  search_not_specified: '未指定',
  search_misjudgment: '誤判定',
  search_non_misjudgment: '非誤判定',
  search_cofind: '捜査',
  search_check: '捜索協力',
  search_arrest: '取調べ',
  search_vehicle_marking: '車両マーキング',
  search_finished_status: '完了ステータス',
  search_finish: '完了',
  search_undone: '未完成',
  search_total_of: '合計',
  search_of_events: '件',
  search_timeout_title: '検索がタイムアウトしました',
  search_timeout_message: 'クエリがタイムアウトしました。クエリの範囲を狭めてください。',
  search_clear: 'クリア',
  search_adv_filter: 'アドバンス要件',
  search_channel_id: 'レンズ',
  search_matched: '一致しました',
  search_inference_matched: '推論が一致しました',
  search_no_match: '一致しませんでした',
  search_cause_default_park: '履歴クエリ',

  /* History: History Events */
  history_event: 'イベント記録',
  history_event_lpr: '車番号',
  history_event_fr: '人物',
  history_event_or: '物体',
  history_event_previous: '前の事件',
  history_event_next: '次の事件',
  history_event_wheelzoom: 'ホイールズーム',
  history_event_previous_tooltip: '前の事件（左クリック）',
  history_event_next_tooltip: '次の事件（右クリック）',
  history_event_back_tooltip: '閉じる（Esc）',
  history_event_map_goto: 'Google マップを開く',
  history_query_by_event: '事件で照会',
  history_device: '識別装置',
  history_employee: '従業員',
  history_recognition: '識別',
  history_tag: '車両マーキング',
  history_mission: 'ミッション',
  history_mission_note: 'ミッションノート',
  history_channel: 'レンズ',
  history_event_prev_page: '前のページ',
  history_event_next_page: '次のページ',
  history_event_no: '第',
  history_event_pages: 'ページ',
  history_event_screen_capture: 'スクリーンショットを取得',
  history_event_screen_capture_info: 'キャプチャされたサイズが画面のサイズと一致しません。ページをリロードしてから再度スクリーンショットを撮影してください。',
  history_show_snapshot: 'スナップショットを表示',
  history_show_video: 'ビデオを表示',

  /* History: 人物 */
  history_fr_name: '名前',
  history_fr_id: 'ID',
  history_fr_age: '年',
  history_fr_tag: 'プロファイルマーク',
  history_fr_note: 'プロファイルノート',
  history_fr_group: 'グループ',
  history_fr_device: '装置',
  history_fr_gps: 'GPS',
  history_fr_open_google_map: 'グーグルマップを開く',
  history_fr_event_category: 'イベントカテゴリ',
  history_fr_score: '識別スコア',
  history_fr_cv: '信頼値',
  history_fr_tag_suspected: 'うたがめ',

  /* History: Urgent */
  history_device_group: 'グループ',
  history_link_user: '関連ユーザー',
  history_user_group: 'グループのユーザー',

  /* QR Code Panel */
  qr_title: '携帯電話通報アプリをダウンロード',

  /* Dashboard: tooltips */
  previous_video: '前の動画',
  next_video: '次の動画',
  play: '再生',
  pause: '一時停止',
  mute: 'ミュート',
  unmute: 'ミュート解除',
  play_rate: '再生率',
  zoom_in: '画面を拡大',
  zoom_out: '画面を縮小',
  zoom_ratio: 'Zoom Ratio',
  panorama_mode: '360°パノラマモード',
  show_only_events: 'このデバイスの事件のみ表示',
  download_video: '動画のダウンロード',
  full_screen: 'フルスクリーン',
  jump_to_live: 'ライブ画面へ移動します',
  back_event: '戻りイベント',
  forward_15s: '15秒進む',
  backward_15s: '15秒戻る',
  the_day_before: '前日',
  the_day_after: '翌日',
  a_minute_ago: '1分前',
  one_minute_after: '1分後',
  zoom_out_the_play: '再生ウィンドウを縮小',
  enlarge_the_play: '再生ウィンドウを拡大',
  mode_1x1: '1x1',
  mode_2x2: '2x2',
  mode_3x3: '3x3',
  mode_4x4: '4x4',
  mode_1_5: '1+5',
  mode_1_12: '1+12',
  mode_2_8: '2+8',
  reorder_device_list: '再注文',
  collapse_the_play: '再生ウィンドウを閉じる',
  expand_the_play: '再生ウィンドウを開く',
  expand_event_list: '事件リストを開く',
  collapse_event_list: '事件リストを折りたたむ',
  more: '詳しく',
  less: '少ない',
  automatic_logout: 'アイドル状態の自動ログアウトを防止',
  no_selected_user: 'デバイスが選択されていません',
  no_streaming: 'ストリーミングなし',
  no_permission_all: 'ライブ映像および履歴映像の視聴権限なし',
  no_permission_live_view: 'ライブ映像の視聴権限なし',
  no_permission_video: '履歴映像の視聴権限なし',
  no_live_stream: '再生可能な実時間ビデオがありません',
  no_video: '再生可能な過去のビデオがありません',
  no_video_within: '{hour} 時間以内にビデオが利用できません',
  video_pause: '一時停止画面にとどまる',
  mode_nxn_switch_hint: 'スイッチ用のデバイスウィンドウにドロップしてください。',

  /* Account Management */
  am_title: 'アカウントと承認',
  am_user: 'ユーザー',
  am_device: 'デバイス',
  am_group: 'グループ',
  am_role: '役割',
  am_search_account: 'アカウント/アカウント名の検索',
  am_role_label: '役割',
  am_edit_not_complete: '編集が未完了です',
  am_cancel_edit: 'この編集をキャンセルしますか？',
  am_map_search_hint: '道路名またはGPSの検索',
  am_map_reset: '座標のリセット',
  am_user_create: 'ユーザーの追加',
  am_user_create_confirm: '一度追加したユーザーは、削除することができません。追加しますか？',

  /* Account Management-device */
  am_search_device: 'デバイス/デバイス名の検索/動画のタイトル',
  am_device_name: '名前',
  am_device_video_name: '動画のタイトル',
  am_device_event_keep: '事件のキープ',
  am_device_video_keep: '動画のキープ',
  am_device_fr_event_keep: '人物のキープ',
  am_device_lpr_event_keep: '車番号のキープ',
  am_device_or_event_keep: '物体のキープ',
  am_device_video_keep_title: '動画',
  am_device_video_keep_tooltip: '動画保持（日）',
  am_device_fr_event_keep_title: '人物イベント',
  am_device_fr_event_keep_tooltip: '人物イベント保持（日）',
  am_device_lpr_event_keep_title: '車番号イベント',
  am_device_lpr_event_keep_tooltip: '車番号イベント保持（日）',
  am_device_or_event_keep_title: '物体イベント',
  am_device_or_event_keep_tooltip: '物体イベント保持（日）',
  am_group_tree_search: 'グループの検索',
  am_device_keep_time: 'キープ期間',
  am_device_keep_time_tip: 'システムデフォルトのキープ期間',
  am_device_keep_time_edit: 'キープ期間の編集',
  am_device_create: 'デバイスの追加',
  am_device_create_confirm: '一度追加したデバイスは、削除することができません。追加しますか？',
  am_device_type_default: '一般',
  am_device_type_bwc: 'ボディーカメラ',
  am_device_type_patrol: 'パト役割',
  am_device_type_phone: '電話',
  am_device_type_ptz: 'PTZ カメラ（球体）',
  am_device_type_box: 'PTZ カメラ（ボックス型）',

  /* Account Management-group */
  am_group_tab_group: 'グループ',
  am_group_tab_quota: '承認する',
  am_search_group: '検索グループ',
  am_group_count: 'グループの数: {count}',
  am_group_online_account: 'オンラインアカウント',
  am_group_name_top: '{min}～{max} 文字を入力してください',
  am_group_title: 'グループ',
  am_view_quota_title: 'クォータの表示',
  am_live_quota_title: 'クォータを配布',
  am_group_quota_out: '閲覧',
  am_group_quota_in: 'リリース',
  an_group_quota_filter: '許可状態',
  an_group_quota_filter_0: '空',
  an_group_quota_filter_1: '未満',
  an_group_quota_filter_2: '満杯',
  am_quota_desc: '使用済/配布済/すべて',
  am_quota_edit: '編集権限',
  am_quota_editing: '編集中',
  am_quota_edit_confirm: '権限を変更してもよろしいですか?',
  am_quota_using_value: '使用する',
  am_quota_divided_value: '割り当て済み',
  am_quota_max_value: '認可数',
  am_quota_parent_group_auth_empty: '親グループ {name} には権限がありません',
  am_quota_parent_group_auth_full: `親グループ {name} の権限はフルです`,
  am_quota_auth_not_enough: '{name} の承認が不十分です',
  am_quota_auth_full: '{name} のすべての権限が割り当てられているため、削除できません',
  // am_quota_error_group_has_account: '{name} グループにアカウントがあるため、親グループを移動できません',
  // am_quota_error_group_has_user: '{name} グループにはユーザーがいます。親グループを移動できません',
  am_quota_error_group_has_user: 'グループを移動できません',
  am_quota_error_group_has_conflict_user: '競合ユーザー：{users}',
  // am_quota_error_group_has_both_quota: 'グループ {name} にはまだ公開および表示の権限があります、所属するグループを調整できません',
  // am_quota_error_group_has_in_quota: 'グループ {name} にはまだ公開権限があるため、所属するグループを調整できません',
  // am_quota_error_group_has_out_quota: 'グループ {name} にはまだ閲覧権限があるため、所属するグループを調整できません',
  am_quota_error_group_has_quota: 'グループを移動すると、グループ権限の数はゼロに戻るため、権限を再割り当てする必要があります。',
  am_add_group: 'グループの追加',
  am_add_subgroup: '新しいサブグループを追加',
  am_parent_group_empty: '所属するグループ名',
  am_parent_group: '所属グループ',
  am_parent_group_hint: '所属するグループ',
  am_group_name: 'グループ名',
  am_group_name_hint: '新しいグループ(1)',
  am_group_name_tip: '{min}～{max} 文字を入力してください',
  am_group_name_duplicate: 'このグループ名はすでに存在します',
  am_group_id: 'ID',
  am_group_updated_time: '変更日時',
  am_group_create_time: '作成日時',
  am_add_group_fail: 'グループの追加にエラーが発生しました',
  am_add_group_pass: 'グループの追加が正常に完了しました',
  am_edit_group_fail: 'グループの編集にエラーが発生しました',
  am_edit_group_pass: 'グループの編集が正常に完了しました',
  am_quota_short_hint1: 'グループ',
  am_quota_short_hint2: 'クォータ不足',
  am_group_create_confirm: '一度追加したグループは削除できません。追加しますか？',
  am_group_department_code: '部門コード',
  am_group_staff_info: '情報',
  am_group_watching: '見てる',
  am_group_account_user: 'ユーザー',
  am_group_account_device: 'デバイス',
  am_group_quota_using: 'を使用して',
  am_group_quota_divided: '分割された',
  am_group_quota_total: '合計',
  am_group_dev_info_snapshot_link_tooltop: 'スナップショットリンク',
  am_group_dev_info_streaming_link_tooltop: 'ストリーミングリンク',
  am_group_dev_info_name: '名前',
  am_group_dev_info_info_name: '装置名',
  am_group_dev_info_video_title: '動画タイトル',
  am_group_dev_info_property: '属性',
  am_group_dev_info_device_id: 'デバイスのシリアル番号',
  am_group_dev_info_device_model_id: 'デバイスモデル',
  am_group_dev_info_device_info_type: 'デバイスタイプ',
  am_group_dev_info_latest_action: '最新のアクション',
  am_group_dev_info_gps: 'GPS',
  am_group_dev_info_gps_timestamp: '時刻',
  am_group_duplicate_name: 'このグループ名は存在しました',
  am_group_edit_notify: '変更されたグループ',
  am_group_edit_confirm: 'グループを変更してもよろしいですか?',
  am_group_add_notify: '追加されたグループ',

  /* Account Management-role */
  am_role_helper_title: '役割管理',
  am_role_helper_intro: '「「役割（Role）」は、様々なシステムリソースのアクセス範囲と機能レベルを定義するために設計された権限の集合体である。役割は、ユーザーアカウントに適用して、そのアカウントが操作できる権限レベルを調整することができます。',
  am_role_helper_desc1: '上位レベルには、下位レベルのアクセス範囲および機能項目が含まれます。',
  am_role_helper_desc1_sub1: 'アクセス範囲（スコープ）： レベルは低いものから高いものまであり、レベルが上がるにつれて範囲が広くなります。',
  am_role_helper_desc1_sub2: '機能レベル（レベル）： レベルは低いものから高いものまであり、レベルが上がるとより多くの権限が与えられる。',
  am_role_helper_desc2: '役割はグローバルなものであり、重複して使用することはできません。',

  /* System Setting */
  // system_global_setting: 'グローバル設定',
  // system_custom_web: 'カスタムウェブページ',
  // system_backend_setting: 'バックエンドの設定',
  // system_server_capability: 'サーバー機能',
  system_setting: '設定',
  system_setting_general: '一般',
  system_setting_recognition: '認識',
  system_setting_logo: 'LOGO',
  system_aibox: 'AiBox',
  system_server_state: 'サーバー',
  system_cert: '証明書',
  system_custom_system_name: 'システム名',
  system_custom_idle_logout_time: 'アイドルログアウト時間',
  system_custom_logout_not: 'ログアウトしない',
  system_custom_app_download: 'APPのダウンロードリンクを表示する',
  system_custom_manual_download: 'マニュアルのダウンロードリンクを表示する',
  system_custom_customize: 'お客様',
  system_custom_favicon: 'タブ上の小アイコン favicon 32*32',
  system_custom_banner: '左サイドバーの展開バナー 130*56',
  system_custom_logo: '左サイドバーの開閉アイコン 56*56',
  system_custom_upload: '画像をアップロードする',
  system_custom_restore: 'デフォルトに戻す',
  system_custom_restore_info: '上記のアイコン設定をデフォルト値に戻します。',
  /* System Manage - System Setting (設定-一般) */
  system_custom_web_customize_func: 'カスタマイズ機能',
  system_custom_web_customize_func_park: '駐車場',
  system_custom_application: 'アプリケーション',
  system_custom_security: 'セキュリティ',
  system_custom_download: 'ダウンロード',
  system_custom_location: '場所',
  system_custom_map: 'マップ',
  system_custom_map_type: 'タイプ',
  system_custom_map_type_0: 'マップ',
  system_custom_map_type_1: '衛星',
  system_custom_announcement: 'アナウンス',
  /* System Manage - System Setting (設定-辨識) */
  system_manage_recognition_fr: '人物認識',
  system_manage_recognition_fr_db: 'データベース',
  system_manage_recognition_fr_photo_count: '登録される写真の数には制限があります',
  system_manage_photo_count: '{count} 張',
  system_manage_recognition_lpr: '車両認識',
  system_manage_recognition_lpr_sub_db: 'サブスクリプション データベース',
  system_manage_recognition_lpr_mission_db: 'タスク データベース',
  system_manage_recognition_lpr_tag_db: 'タグデータベース',
  system_manage_recognition_lpr_region: 'ナンバープレートの表示', // NOT DO
  system_manage_recognition_package_frequency: 'パッケージの頻度',
  system_manage_recognition_region: '地域', // NOT DO
  /* System Manage - System Setting (設定-標誌) */
  system_manage_logo_open_file: 'ファイルを開く',
  system_manage_logo_reset: '復元',
  system_manage_logo_favicon_title: 'ファビコン',
  system_manage_logo_favicon_desc1: 'ブラウザー タブ上の小さなアイコン。',
  system_manage_logo_favicon_desc2: '32X32 ピクセルの PNG ファイルを使用することをお勧めします。',
  system_manage_logo_login_title: 'ログインページ',
  system_manage_logo_login_desc1: 'ログイン ページの中央にある大きなアイコンは、横向きのロゴとして使用できます。',
  system_manage_logo_login_desc2: '130X56 ピクセルの SVG ファイルを使用することをお勧めします。',
  system_manage_logo_dashboard_logo_title: 'ウェブページのロゴ',
  system_manage_logo_dashboard_logo_desc1: '左側のナビゲーション バーのロゴ。',
  system_manage_logo_dashboard_logo_desc2: '56X56 ピクセルの SVG ファイルを使用することをお勧めします。',
  system_manage_logo_dashboard_banner_title: 'サービス ディスパッチ バナー',
  system_manage_logo_dashboard_banner_desc1: 'サービス ディスパッチ ページの左上隅にあるアイコンは、横方向のロゴを適用できます。',
  system_manage_logo_dashboard_banner_desc2: '130X56 ピクセルの SVG ファイルを使用することをお勧めします。',

  /* System Manage - Device */
  system_device: 'デバイス',
  system_device_select_model_hint: '最初にデバイスモデルを選択してください。',
  system_device_tab_video: 'ビデオ',
  system_device_tab_param: 'パラメータ',
  system_device_tab_recognition: '認識',
  system_device_channel_account: 'チャンネル/アカウント',
  system_device_recg_not_support: 'このデバイスは認識機能をサポートしていません',

  /* System Manage - AiBoxMgr */
  setting_aibox_top_box: '識別ボックス',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: '識別ボックスの数',
  setting_aibox_top_abnormal_count: '異常数',
  setting_aibox_page_start: '第',
  setting_aibox_page_end: '件',
  setting_aibox_page_total: '検索結果',
  setting_aibox_total_of: 'データベース総',
  setting_aibox_of_records: '件データ',
  setting_aibox_goto_page: 'ページ送り',
  setting_aibox_state_normal: '正常',
  setting_aibox_state_abnormal: '異常',
  setting_aibox_table_name: '識別ボックス',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: 'タスク',
  // setting_aibox_table_temp: '温度',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: '識別リソース',
  setting_aibox_table_updated_time: '更新時間',
  setting_aibox_delete_title: 'データ削除',
  setting_aibox_task_device: 'デバイス',
  setting_aibox_type_or: '物体識別',
  setting_aibox_type_fr: '人物識別',
  setting_aibox_type_lpr: '車両番号標識別',
  setting_aibox_task_recognition_type: '識別カテゴリ',
  setting_aibox_task_recognition_state: '識別ステータス',
  setting_aibox_not_connected: '識別ボックスが接続されていません',
  setting_aibox_task_status_0: '有効',
  setting_aibox_task_status_1: '識別中',
  setting_aibox_task_status_10: 'デバイス未公開',
  setting_aibox_task_status_11: 'ストリーム接続中',
  setting_aibox_task_status_12: '許可数不足',
  setting_aibox_task_status_13: 'システムエラー',
  setting_aibox_task_status_14: '設定ファイルバージョンがサポートされていません',
  setting_aibox_task_status_15: '識別機能がサポートされていません',
  setting_aibox_task_status_16: 'パラメータの内容が一致しません',
  setting_aibox_task_recognition_or: 'オブジェクト',
  setting_aibox_task_recognition_lpr: '車両',
  setting_aibox_delete_aibox: 'この識別ボックスを削除しますか？',
  setting_aibox_delete_aibox_hint: 'AiBox タスクがまだ残っているため削除できません',
  setting_aibox_delete_notify_title: '識別ボックスの削除',
  setting_aibox_delete_pass: '識別ボックスの削除に成功しました',
  setting_aibox_delete_fail: '識別ボックスの削除に失敗しました',
  setting_aibox_delete_aibox_task: 'このデバイスの識別タスクを削除しますか？',
  setting_aibox_task_delete_notify_title: '識別タスクの削除',
  setting_aibox_task_delete_pass: '識別タスクの削除に成功しました',
  setting_aibox_task_delete_fail: '識別タスクの削除に失敗しました',

  /* System Manage - System Setting (設定) */
  system_manage_setting_save_toast: '設定を編集',
  system_manage_setting_save_confirm_header: 'システム設定',
  system_manage_setting_save_confirm_msg: '設定を変更してもよろしいですか？',
  system_manage_setting_leave_confirm_header: '編集をキャンセルする',
  system_manage_setting_leave_confirm_msg: '編集をキャンセルしてもよろしいですか？',
  system_manage_setting_init: '初期化',
  system_manage_setting_init_toast: '初期化データ',

  /* System Manage - Server State (伺服器) */
  system_server_state_title_srv: 'サーバー / サービス',
  system_server_state_mgr_header: '{name} サーバー管理',
  system_server_state_mgr_keep_space: '予備スペース',
  system_server_state_mgr_save_toast: 'サーバーの構成',
  system_server_state_mgr_save_confirm_header: 'サーバーの構成',
  system_server_state_mgr_save_confirm_msg: '設定を変更してもよろしいですか？',
  system_server_state_mgr_restart_toast: 'サーバーを再起動します',
  system_server_state_mgr_restart_confirm_header: 'サーバーを再起動します',
  system_server_state_mgr_restart_confirm_msg: '{name} サーバーを再起動してもよろしいですか？',
  system_server_state_hdd_warn: 'ディスク空間異常',

  /* AI Box */
  ai_recognition_setting: '識別設定',
  ai_device_recognition: 'デバイスの識別',
  ai_person_recognition: '人物認識',
  ai_could_recognition: 'クラウド識別',
  ai_object_recognition: 'オブジェクト識別',
  ai_object: 'オブジェクト',
  ai_license_plate_recognition: '車両のナンバープレート識別',
  ai_license_plate: 'ナンバープレート',
  ai_updated_time: '更新時間',
  ai_recognition: '識別',
  ai_notify: '通知する',
  ai_notify_setting: '通知設定',
  ai_box: 'AI Box',
  ai_task_add: '追加中',
  ai_task_edit: '編集中',
  ai_task_delete: '削除中',
  ai_status_0: 'ゆうこうか',
  ai_status_1: '識別中',
  ai_status_10: 'デバイス未公開',
  ai_status_11: 'ストリーム接続中',
  ai_status_12: '許可数不足',
  ai_status_13: 'システムエラー',
  ai_status_14: '設定ファイルバージョンがサポートされていません',
  ai_status_15: '識別機能がサポートされていません',
  ai_status_16: 'パラメータの内容が一致しません',
  ai_try_connect: 'ストリームに接続を試みています',
  ai_select_aibox: '識別ボックスを選択してください',
  ai_temperature: '温度',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: '識別リソース',
  ai_other_resources: 'その他の識別ボックス',
  ai_add_notification_task: '通報タスクの追加',
  ai_image_preview: '画像プレビュー',
  ai_recg_fps_level: '識別頻度',
  ai_repeat_interval: '重複イベントの間隔',
  ai_stay_sec: '滞在時間',
  ai_category: 'カテゴリ',
  ai_subscriber: '受信者',
  ai_group_name_placeholder: '部署または名前',
  ai_show_checked: 'チェック済みの表示',
  ai_edit_receiver: '受信者の編集',
  ai_mode: 'モード',
  ai_detect: '検出',
  ai_object_detect: 'オブジェクト検出',
  ai_motion_detect: '移動検出',
  ai_detect_target_threshold: '検出目標の閾値',
  ai_motion_level: '検出感度',
  ai_advanced_settings: '高度な設定',
  ai_person_level: '人',
  ai_car_level: '自動車',
  ai_bike_level: '二輪車',
  ai_truck_level: 'トラック',
  ai_bus_level: 'バス',
  ai_warning_area: '警戒区',
  ai_delete_data: 'データを削除する',
  ai_device: '装置',
  ai_recognition_category: '識別カテゴリ',
  ai_recognition_status: '識別状態',
  ai_level_0: '閉める',
  ai_level_1: '最低',
  ai_level_2: '低',
  ai_level_3: '中',
  ai_level_4: '高',
  ai_level_5: '最高',
  ai_motion_level_1: '低',
  ai_motion_level_2: '中',
  ai_motion_level_3: '高',
  ai_insufficient_recognition_resources: '識別リソースが不足しています',
  ai_no_boxes_available: '申し訳ありませんが、使用可能な識別ボックスがありません。',
  ai_edit_not_complete: '設定が保存されない',
  ai_cancel_edit: '本当にキャンセルしてもよろしいですか?',
  ai_parameter_adjustment: 'パラメータ調整',
  ai_event_score_greater_than: 'イベント トリガー スコアはイベント候補スコア以上である必要があります',
  ai_candidate_score_adjust: 'イベント候補スコアが調整されました {score}',
  ai_event_score_adjust: 'イベントトリガースコアが調整されました {score}',
  ai_area1: 'エリア 1',
  ai_area2: 'エリア 2',
  ai_area3: 'エリア 3',
  ai_new_recognition: '識別タスクを追加',
  ai_recog_task_add_successfully: '識別タスクの追加に成功しました',
  ai_edit_recognition: '識別タスクを編集',
  ai_recog_task_edit_successfully: '識別タスクの編集に成功しました',
  ai_task_already_added: '新しい認識タスクが追加されました。別の認識タスクを追加する前に設定を完了してください。',
  ai_subscriber_add_user: '{name} が通知受信者として設定されています',
  ai_roi_set: '識別範囲の調整',
  ai_roi_lock: '識別範囲をロックする',
  ai_tooltip_roi_reset: 'デフォルトの範囲に復元する',
  ai_tooltip_mode: '<strong style="color: #FFE99F;">オブジェクト検出</strong>: 選択されたオブジェクトが検出された場合に通知を送信します<br/><strong style="color: #FFE99F;">移動検出</strong>: 画面内で移動物体が検出された場合に通知を送信します',
  ai_tooltip_or: 'オブジェクト検出: 選択されたオブジェクトが検出された場合に通知を送信します',
  ai_tooltip_md: '移動検出: 画面内で移動物体が検出された場合に通知を送信します',
  ai_tooltip_threshold: '閾値が低いほど、画面上のオブジェクトをより多く見つけることができますが、同時に精度が低下する可能性もあります',
  ai_tooltip_motion: '感度が高いほど、画面内の移動変化に対してより敏感になります',
  ai_tooltip_recg_fps_level: 'オブジェクトの移動速度が速いほど、識別頻度を高く設定することをお勧めします',
  ai_tooltip_repeat_interval: 'イベントが再発生すると、この設定時間の間隔で再び通知を送信し、イベントをアップロードします',
  ai_tooltip_stay_sec: 'オブジェクトが警戒区域に滞在する時間が経過した後に通知を送信します',
  ai_tooltip_warning_area: '最大で8つのポイントの多角形で、オブジェクトが警戒区域に触れると通知が送信されます。',

  /* AI Box - recognition setting */
  ai_tag: 'タグ',
  ai_note: '備考',
  ai_editor: '設定者',
  ai_expert_mode: 'エキスパートモード',
  ai_full_face_mode: 'フルフェイスモード',
  ai_half_face_mode: '半顔モード',
  ai_image_source: '画像ソース',
  ai_external_cam: '外部カメラ',
  ai_internal_cam: '内部カメラ',
  ai_image_source_tip: '認識された画像ソースを設定する',
  ai_param_source: 'パラメータソース',
  ai_global_config: 'システムのデフォルト',
  ai_user_config: 'カスタマイズ',
  ai_param_source_tip: '<strong style="color: #FFE99F;">システムのデフォルト</strong>: システムのデフォルトのパラメータを使用します。<br/><strong style="color: #FFE99F;">カスタマイズ</strong>: カスタマイズされたパラメータを使用します。',
  ai_enable: '有効',
  ai_enable_tip: '顔認識機能を有効/無効にすることができます',
  ai_model_country: 'モデルの国',
  ai_model_country_tip: 'モデルの国を選択することで、モデルの精度を向上させることができます',
  ai_face_size_ratio: '顔のサイズ比率',
  ai_face_size_ratio_tip: '顔のサイズ比率を調整することで、顔のサイズを調整することができます',
  ai_detect_mode: '比較モード',
  ai_precise_detect: '正確な比較',
  ai_quick_detect: '簡単な比較',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">正確な比較</strong>: より高い精度の結果を出力できます; <br/><strong style="color: #FFE99F;">高速比較</strong>: 識別結果を迅速に出力できます',
  ai_trigger_score: 'トリガースコア',
  ai_candidate_score: '候補者のスコア',
  ai_global_face_trigger_score_tip:
  `<h3 style="color: #FFE99F;">スコアの説明</h3>
  <h4 style="margin-bottom: 4px;">厳しい (>90)：</h4>
  <p style="margin: 0;">管理エリアへの出入りなどのアクセス制御シナリオ。</p>
  <h4 style="margin-bottom: 4px;">正確な (87-89)：</h4>
  <p style="margin: 0;">サインイン/チェックアウトなどのチェックイン シナリオ。</p>
  <h4 style="margin-bottom: 4px;">適度 (84-86)：</h4>
  <p style="margin: 0;">イベントのチェックインなどのチェックイン シナリオ。</p>
  <h4 style="margin-bottom: 4px;">けいこく (80-83)：</h4>
  <p style="margin: 0;">アセンブリ シーン (対象オブジェクトのアセンブリ監視など)。</p>
  <h4 style="margin-bottom: 4px;">けいかい (<80)：</h4>
  <p style="margin: 0px;">不審物を警戒するパレードなどの警戒現場。</p>
  <p style="margin-top: 20px; color:#F9AEB0; font-size:16px;">スコアが低いほど、誤検知が多くなる可能性があります。スコアが高いほど登録情報と類似していることを通知します。</p>
  `,
  ai_global_fr_event_score_level1: '厳しい',
  ai_global_fr_event_score_level2: '正確な',
  ai_global_fr_event_score_level3: '適度',
  ai_global_fr_event_score_level4: 'けいこく',
  ai_global_fr_event_score_level5: 'けいかい',
  ai_global_fr_event_score_level1_tip: 'アクセス制御シナリオ、例えば管理エリアへの出入り',
  ai_global_fr_event_score_level2_tip: 'チェックインシナリオ、例えばサインイン/チェックアウト',
  ai_global_fr_event_score_level3_tip: 'チェックインシナリオ、例えばイベントのチェックイン',
  ai_global_fr_event_score_level4_tip: 'アセンブリシーン、例えば対象オブジェクトのアセンブリ監視',
  ai_global_fr_event_score_level5_tip: '警戒現場、例えば不審物を警戒するパレード',
  ai_full_face_trigger_score_tip: '正面モードでの類似度の閾値です。閾値が低いほど多くの比較結果が生成されますが、同時に精度が低下する可能性があります。',
  ai_full_face_candidate_score_tip: 'フルフェイスモードにおける候補の閾値です。閾値が低いほど、より多くの候補を参照できます。',
  ai_mask_trigger_score_tip: 'マスクモードでの類似度の閾値です。閾値が低いほど多くの比較結果が生成されますが、同時に精度が低下する可能性があります。',
  ai_mask_candidate_score_tip: 'マスクモードにおける候補文字の閾値です。閾値が低いほど、参照する候補が多くなります。',
  ai_detect_threshold_level: '顔検出閾値',
  ai_detect_threshold_level_tip: '顔検出のしきい値。しきい値が低いほど、画面上でより多くの顔を検出できますが、同時に精度が低下する可能性があります。',
  ai_event_post_mode: 'アップロードモード',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">一致一致</strong>: 一致したイベントのみがアップロードされます; <br/><strong style="color: #FFE99F;">すべての識別</strong>: すべての識別結果がアップロードされます',
  ai_event_match: '一致する',
  ai_event_all: '全て',
  ai_notify_filter_mode: 'イベントレポートモード',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">一致のみ</strong>: 一致するイベントのみが報告されます。<br/><strong style="color: #FFE99F;">すべて認識</strong>: すべてのイベントが通知されます。<br/><strong style="color: #FFE99F;">一致しない</strong>: 一致しないイベントのみが報告されます。<br/><strong style="color: #FFE99F;">報告されません</strong>:イベントは報告されません。',
  ai_notify_match: '一致する',
  ai_notify_all: '全て',
  ai_notify_not_match: '比較が一致しません',
  ai_notify_none: '報告しないこと',
  ai_event_duration: 'イベントの繰り返し間隔',
  ai_noise_event_duration: 'ノイズイベントの繰り返し間隔',
  ai_event_duration_tip: 'イベントが繰り返し発生した場合、設定した時間間隔後に再度アップロードイベントが送信されます。',
  ai_noise_event_duration_tip: '當噪音車輛事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_repost_data_store_limit: '再投稿イベントの保持制限',
  ai_repost_data_store_limit_tip: 'イベントが繰り返し発生した場合、設定した回数後に再度アップロードイベントが送信されます。',
  ai_lpr_frequency: '識別頻度',
  ai_lpr_frequency_tip: '1秒あたりに認識されるフレーム数',
  ai_lpr_size_ratio: '最小ナンバープレート比率',
  ai_lpr_size_ratio_tip: '検出された最小ナンバー プレート サイズ (画面に対する割合)',
  ai_lpr_detect_mode: '比較モード',
  ai_noise_car_decibel_threshold: '騒音デシベル閾値',
  ai_noise_car_decibel_threshold_tip: 'デシベル値が閾値に達したとき、騒音車イベントを発生させる',
  ai_noise_car_event_duration_tip: 'デシベル値が持続的に閾値に達する場合、騒音車イベントはどのくらいの頻度で送信されますか',
  ai_decibel_offset: 'オフセット',
  ai_decibel_offset_tip: 'デシベル値補正',
  ai_confirm_count: '確認回数',
  ai_confirm_count_tip: '値が大きいほど結果が出力されにくくなりますが、出力結果の精度は高くなります。',
  ai_accept_diff_num: '許容される数値の差',
  ai_accept_diff_num_tip: '認識結果を修正する桁数',
  ai_indent: '識別境界',
  ai_indent_tip: '検出されたナンバープレートがこの境界を超える場合、認識結果は出力されません',
  ai_is_complete_mode: 'フルモード',
  ai_is_complete_mode_tip: '有効にすると完全な識別結果のみが出力され、それ以外の場合は識別結果のみが出力されます。',
  ai_roi: '識別範囲',
  ai_roi_tip: '境界線を引いて認識範囲を調整可能',
  ai_event_unit: 'ペン',
  ai_low: '低い',
  ai_medium: '中い',
  ai_high: '高い',
  ai_setting_success: '設定成功',
  ai_setting_fail: 'セットアップに失敗しました',
  ai_no_view_permission: '閲覧権限がありません',
  ai_no_device_setting: 'デバイスパラメータがありません',
  ai_no_user_setting_info: 'デバイスパラメータなし、デフォルト値をロードします',

  /* BoviLive notify */
  bl_subscriber_checked: 'チェック済み ({count})',
  bl_notice_me: '自分に通知',

  /* LINE notify */
  line_notify: 'LINE 通知',
  line_notify_add: 'つうほうのついか',
  line_description: 'LINEの説明',
  line_group_link: 'LINEグループのリンク',
  line_link_method: 'バインド方法を選択してください：',
  line_link_url: 'URLを開く',
  line_link_url_hint: 'URLを開く',
  line_link_qr: 'QRコードをスキャンする',
  line_link_qr_hint: 'QRコードをスキャンする',
  line_link_code: 'トークンを入力してください',
  line_link_code_hint: '確認コードを入力してください',
  line_link_token: 'トークン',
  line_link_token_hint: 'ワンドの長さが制限を超えています。再入力してください',
  line_group_choose: 'LINEと連動',
  line_open_browser: 'ブラウザを開く',
  line_open_system_browser: 'システムブラウザで開く',
  line_linking_code: '識別子',
  line_link: 'バインドする',
  line_linking_hint: 'まだバインドされていません。バインドの手続きを完了してください。',
  line_link_success: 'バインドが成功しました',
  line_link_fail: 'バインドに失敗しました',
  line_link_status: 'バインド状態',
  line_link_status_yes: 'バインド済み',
  line_link_status_no: 'バインド未満',
  line_unlink: 'バインド解除',
  line_unlink_confirm: 'バインド解除を本当に行いますか?',
  line_notify_remove: 'この通知設定を本当に削除しますか?',
  line_message_count: 'メッセージ数',
  line_message_reset: 'リセット時間',
  line_notify_test: 'テストメッセージを送信する',
  line_notify_test_success: 'テストメッセージが送信されました',
  line_edit: '情報の変更',
  line_edit_success: '情報の変更に成功しました',
  line_note_char_warn: 'メモは{count}文字を超えることができません',
  line_description_char_warn: '説明は{count}文字を超えることができません',
  line_add_success: '正常に追加されました',
  line_add_fail: '追加に失敗しました',
  line_tip_title: '製本工程',
  line_tip_url_1: '事前にLINEグループを作成し、そのグループにLINE Notifyを招待してください',
  line_tip_url_2: '「LINEと連携」をクリック',
  line_tip_url_3: 'アカウントのパスワードを入力後、「ログイン」をクリックしてください',
  line_tip_url_4: '通知するグループを選択した後、「同意してリンク」をクリックしてください',
  line_tip_url_5: 'LINEグループに新規追加した関連情報が表示されたら設定完了です',
  line_tip_qr_2: '携帯電話で QR コードをスキャン',
  line_tip_token_1: '事前にLINEグループを作成し、そのグループにLINE Notifyを招待してください',
  line_tip_token_2: 'LINE Notify ページにログイン',
  line_tip_token_3: '「個人ページ」をクリック',
  line_tip_token_4: '「トークンの発行」をクリック',
  line_tip_token_5: 'トークン名を入力し、通知するグループを選択した後、「発行」をクリックしてください',
  line_tip_token_6: 'トークンをコピー',
  line_tip_token_7: 'トークンを Bovia 設定ページにコピーし、[バインド] をクリックします',
  line_tip_token_8: 'LINEグループに新規追加した関連情報が表示されたら設定完了です',

  /* AI Box - LPR */
  ai_lpr_tag: '車両マーキング',

  /* 人辨資料庫 */
  fr_title: 'プロファイル管理',
  fr_info: 'プロファイル情報',
  fr_filter_name: '名前',
  fr_filter_name_hint: '山田太郎', // 無名氏：山田太郎（やまだ たろう，Yamada Taro）、山田花子（やまだ はなこ，Yamada Hanako）
  fr_filter_tag: 'タグ',
  fr_filter_time: '更新時間',
  fr_filter_id: 'ID',
  fr_filter_id_hint: 'Z123456789',
  // fr_filter_feature: '特徴写真',
  fr_filter_enabled: 'プロファイル情報',
  fr_filter_tooltip: 'アドバンス要件', // '高度な条件',
  frInfo_idCard_filter: 'ID',
  frInfo_feature_filter: '登録写真',
  frInfo_feature_filter_0: '全て',
  frInfo_feature_filter_1: '任意の有効',
  frInfo_feature_filter_2: '任意の無効',
  frInfo_feature_filter_3: '全て無効', // '無啟用'
  frInfo_feature_filter_4: '未登録', // 沒用過
  frInfo_enabled_filter: 'プロファイルステータス',
  frInfo_enabled_filter_all: '全て',
  frInfo_enabled_filter_0: '停止', // disabled
  frInfo_enabled_filter_1: '使用', // enabled
  fr_new_fr_tooltip: 'プロファイルの作成',
  fr_db_info_tooltip: 'データベース情報',
  fr_tag_mgr_tooltip: 'タグ管理',

  fr_tag_mgr_title: 'タグ管理',
  fr_tag_add: 'プロファイルタグの作成',
  fr_tag_add_placeholder: 'タグの作成',
  fr_tag_duplicate_hint: 'タグが存在します。別のマークを入力してください。',
  fr_tag_total: '{total} 個のタグ',
  fr_tag_total_count: '{count}/{total} タグ',
  fr_tag_add_hint: '追加後にタグを削除できません。本当に追加しますか？',

  fr_db_info_title: 'データベース情報',
  fr_db_info_data_cnt: '{count}筆',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: '版：',

  fr_table_register: '登録',
  fr_table_photo: 'アバター', //頭像
  fr_table_name: '名前',
  fr_table_id: 'ID',
  fr_table_tag: 'タグ',
  fr_table_update_time: '更新時間',
  fr_table_create_time: '時間を作る',
  fr_table_empty_hint: 'クエリ条件を入力してください',
  fr_table_searching: '検索中',
  fr_table_delete_pass: '削除成功',
  fr_table_delete_fail: '削除に失敗しました',

  fr_create_id_duplicate: 'そのIDはすでに存在しています',
  fr_info_data_title: 'プロファイル情報',
  fr_info_data_delete_confirm: '{name} の情報をすべて削除してもよろしいですか?',
  fr_info_data_enable: '有効',
  fr_info_data_disable: '無効',
  fr_info_data_name: '名前',
  fr_info_data_gender: '性別',
  fr_info_data_id: 'ID',
  fr_info_data_check_id: 'IDを確認する',
  fr_info_data_age: '年',
  fr_info_data_age_edit: '誕生日',
  fr_info_data_tag: 'タグ',
  fr_info_data_note: '備考',
  fr_more: '詳しく',
  fr_less: '少ない',
  fr_info_data_phone: '電話',
  fr_info_data_birth_place: '出生地',
  fr_info_data_city: '市',
  fr_info_data_address: '住所',
  fr_info_data_job: '職業',
  fr_info_photo_title: '登録写真',
  fr_info_photo_registered: '登録',
  fr_info_photo_registered_title: '登録の写真',
  fr_info_photo_unregistered_title: '未登録の写真',
  fr_info_photo_registered_hint: '登録写真満杯',
  fr_info_photo_registering: '登録する...',
  fr_info_photo_add: '写真を追加',
  fr_info_other_title: 'データ',
  fr_info_other_src: 'ソース',
  fr_info_other_src_0: '自作',
  fr_info_other_src_1: '輸入',
  fr_info_other_update_time: '更新時間',
  fr_info_other_create_time: '時間を作る',
  fr_info_data_required: '必須',
  fr_info_data_phone_hint: '数字、+、-、()のみを使用できます',
  fr_info_data_gender_0: '他の',
  fr_info_data_gender_1: '男',
  fr_info_data_gender_2: '女',
  fr_info_data_gender_9: '未知',
  fr_info_date_fetch: 'プロファイル情報を取得する',
  fr_info_data_close_hint_title: '警告',
  fr_info_data_close_hint_msg: '変更を破棄してもよろしいですか?',
  fr_feature_computing: '計算...',
  fr_feature_delete_confirm: '写真を削除してもよろしいですか?',
  fr_feature_1: '無効',
  fr_feature_2: '利用不可',
  fr_feature_3: '貧しい',
  fr_feature_4: '利用可能',
  fr_feature_5: '最高',
  fr_feature_invalid_hint: '特徴スコアが無効なので登録できません',
  fr_face_0: '全顔特徴量', // '全臉特徵值',
  fr_face_1: '半顔特徴量', // '半臉特徵值',
  fr_face_score: '特徵スコア {score}',
  fr_face_0_score: 'Facial feature {score}',
  fr_face_1_score: 'Half-face feature {score}',
  fr_action_set_avatar: 'アバターを設定する',
  fr_action_download_photo: '写真をダウンロード',
  fr_action_delete_feature: '写真の削除',
  fr_no_birthday: "（年／月／日）",

  fr_photo_new: '写真を追加',
  fr_photo_available_photo: '利用可能な写真：',
  fr_photo_limit: 'サイズが 10 MB 未満で、bmp、jpeg、jpg、または png 形式の画像ファイルをアップロードしてください。',
  fr_photo_drag: '写真をここにドラッグします',
  fr_photo_or: 'または',
  fr_photo_browse: 'ブラウズ',
  fr_photo_error_format: '{fileName} 形式はサポートされていません',
  fr_photo_error_size: '{fileName} ファイルが 10M を超えています',
  fr_photo_error_count: '利用可能な写真の数を超えているため、削除してください',

  fr_sample_ok_title: '正しいサンプル',
  fr_sample_ok_desc1: '写真の縦の高さの 70%',
  fr_sample_ok_desc2: '前を向いてカメラを直接見る必要があります',
  fr_sample_ok_desc3: '穏やかな表情',
  fr_sample_ok_desc4: '十分な光',
  fr_sample_ok_desc5: '鮮明なカラフルな写真',
  fr_sample_ok_desc6: '眉毛を見せる',
  fr_sample_ok_desc7: '写真に写っている顔は1つだけ',
  fr_sample_fail_title: '間違ったサンプル',
  fr_sample_fail_desc1: '過剰な表情',
  fr_sample_fail_desc2: '側面を使用してはなりません',
  fr_sample_fail_desc3: '複数の顔を含めることはできません',

  fr_create: '顔認証を登録する',
  fr_create_pass: '人物の作成に成功しました',
  fr_create_fail: '人物の作成に失敗しました',
  fr_edit: '人物の編集',
  fr_edit_pass: '人物の編集が成功しました',
  fr_edit_fail: '人物の編集が失敗しました',
  fr_create_fail1: '名前またはIDを入力してください',
  fr_feature_upload: '登録写真をアップロードする',
  fr_feature_delete: '登録写真を削除する',
  fr_feature_enable: '写真の登録を有効にする',
  fr_feature_enable_hint: '最初にその人を有効にしてください',
  fr_feature_disable: '登録写真を無効にする',

  fr_history_register_photo: '新プロファイル情報',
  fr_history_registered_photo_full_hint: '写真がいっぱいです。',
  fr_history_registered_new_photo: '新しい写真を登録しました',
  fr_history_registered_add_photo_to_fr: 'この人物に写真を追加する',
  fr_history_registered_search_fr_db: '文字データベースを検索',
  fr_history_registered_assigned_fr_confirm: 'この写真を次の人に登録してもよろしいですか？',

  // 物件辨識
  or_class_1: '人',
  or_class_2: '自動車',
  or_class_3: '二輪車',
  or_class_4: 'トラック',
  or_class_5: 'バス',
  or_cloud: 'クラウド',
  or_full_view: 'フルビュー',

  // 縮時攝影
  time_lapse_task: '任務設定',
  time_lapse_list: '任務清單',
  time_lapse_export: '影片列表',

  // Dashboard-Device Param ===
  device_param_title: 'デバイスパラメータ',
  device_param_aicam_save_title: 'AiCamパラメータを設定する',
  device_param_patrol_save_title: 'Patrolパラメータを設定する',
  device_param_bovicast_save_title: 'Bovicastパラメータを設定する',
  // 設備狀態 ---
  device_param_status: 'デバイスのステータス',
  // 設備狀態>連線狀態
  // 設備狀態>頻道狀態
  // 設備狀態>流量
  // 設備狀態>名稱
  // 設備狀態>感測器
  // 設備狀態>其他
  // 影像設定 ---
  device_param_video: '画像設定',
  device_param_video_quality: '品質',
  device_param_video_quality_w: '幅',
  device_param_video_quality_h: '高',
  device_param_video_quality_framerate: 'フレームレート（fps）',
  device_param_video_quality_bitrate: 'ビットレート（Kbps）',
  // 影像設定>頻道
  device_param_video_channel: 'チャンネル',
  device_param_video_channel_image_source_type: 'ビデオソース',
  device_param_video_channel_image_source_type_0: '外部',
  device_param_video_channel_image_source_type_1: '内部（リアカメラ）',
  device_param_video_channel_image_source_type_2: '内部（フロントカメラ）',
  device_param_video_channel_image_source_type_3: 'UVC',
  device_param_video_channel_image_source_type_4: '自動',
  device_param_video_channel_stream_url: 'ビデオ URL',
  // 影像設定>發布
  device_param_video_publish: 'リリース',
  device_param_video_channel_index: 'チャンネルを公開する',
  device_param_video_auto_start: '自動公開',
  device_param_video_audio_mute: 'ミュート',
  device_param_video_code_codec: '画像コーディング',
  device_param_video_osd: 'OSD',
  device_param_video_osd_date_enabled: 'OSD-時間',
  device_param_video_osd_imu_enabled: 'OSD-慣性計測ユニット', // Inertial measurement unit
  device_param_video_osd_temp_enabled: 'OSD-温度計',
  device_param_video_osd_font_size_index: 'OSD-フォントサイズ',
  device_param_video_osd_decibel_enabled: 'OSD-デシベル値',
  device_param_video_osd_signal_info_enabled: 'OSD-シグナル強度',
  device_param_video_osd_battery_level_percentage_enabled: 'OSD-電池残量（パーセント）',
  device_param_video_osd_battery_voltage_enabled: 'OSD-電池残量（電圧値）',
  device_param_video_customize_quality: '品質をカスタマイズする',
  device_param_video_bitrate_mode: 'ビットレート制御モード',
  // 影像設定>錄影
  device_param_video_record: 'ビデオ',
  device_param_video_record_duration: '動画の長さ',
  // 參數設定 ---
  device_param_setting: 'パラメータ設定',
  device_param_setting_charging_control: '充電状況連動制御',
  // 參數設定>系統
  device_param_setting_system: 'システム',
  device_param_setting_system_battery_level_percentage_method: 'バッテリー レベル パーセンテージ メソッド',
  device_param_setting_system_battery_level_percentage_method_dc: 'DC',
  device_param_setting_system_battery_level_percentage_method_12v: '12V',
  device_param_setting_system_battery_level_percentage_method_24v: '24V',
  // 參數設定>介面
  device_param_setting_interface: 'インターフェース',
  device_param_setting_btn_long_press_time: 'ボタン長押しのトリガー時間',
  // 參數設定>語音通話
  device_param_setting_voice_call: '音声通話',
  device_param_setting_auto_answer: '自動応答',
  // 參數設定>公告
  device_param_setting_announcement_notify_type: '音声放送',
  device_param_setting_announcement_notify_type_0: 'なし',
  device_param_setting_announcement_notify_type_1: '音声',
  device_param_setting_announcement_notify_type_2: 'ビープ',
  device_param_setting_announcement_display_type: '見せる',
  device_param_setting_announcement_display_type_0: '見せないで',
  device_param_setting_announcement_display_type_1: '1 回表示',
  device_param_setting_announcement_display_type_2: '永遠に',
  // 參數設定>事件
  device_param_setting_event: 'イベント',
  device_param_setting_event_imu_front_back_threshold: 'IMU 前面/背面しきい値',
  device_param_setting_event_imu_right_left_threshold: 'IMU 右/左しきい値',
  device_param_setting_event_temperature_threshold: '温度しきい値',
  device_param_setting_notify_in_app: 'アプリからイベント通知',
  device_param_setting_notify_in_app_0: '一度もない',
  device_param_setting_notify_in_app_1: 'いつも',
  device_param_setting_notify_in_app_2: 'ビューを非表示にする場合',
  device_param_setting_notify_in_app_3: 'ビューを表示する場合',
  device_param_setting_wake_up_screen: 'イベントトリガー画面',
  device_param_potral_setting_audio_notify_type_0: 'なし',
  device_param_potral_setting_audio_notify_type_1: '警報音',
  device_param_potral_setting_audio_notify_type_2: '音声プロンプト', // 語音提示
  device_param_bovicast_setting_audio_notify_type_0: 'なし',
  device_param_bovicast_setting_audio_notify_type_1: 'ビープ',
  device_param_setting_announce_mission_note: 'ミッションノートを発表',
  device_param_setting_announce_tag_note: '車両発言メッセージを放送',
  device_param_setting_chase_event_popup_time: 'キャプチャウィンドウの自動終了時間',
  device_param_setting_jpg_quality: 'JPG品質',
  device_param_setting_crop_jpg_quality: '剪るJPG品質',
  device_param_setting_enable_vibration: '振動プロンプト',
  // 參數設定>診斷
  device_param_setting_diagnose: '診断',
  device_param_setting_min_audio_volume: '最小音量リマインダー',
  // 參數設定>儲存裝置
  device_param_setting_disk: 'ストレージデバイス',
  device_param_setting_disk_threshold: '予備スペース',
  device_param_setting_disk_cleanup_type: '削除命令',
  device_param_setting_disk_cleanup_type_0: '削除は禁止されています',
  device_param_setting_disk_cleanup_type_1: '動画ファイルは最初に削除されます',
  device_param_setting_disk_cleanup_type_2: 'イベントを特定して最初に削除する',
  // 參數設定>定位
  device_param_setting_locate: '位置',
  device_param_setting_post_duration: 'ターゲティング間隔を更新する',
  // 參數設定>AI
  // device_param_setting_ai: 'AI',
  // device_param_setting_ai_hidden: 'AIモード',
  // device_param_setting_ai_hidden_0: 'AIをオフにする',
  // device_param_setting_ai_hidden_1: 'AIをオンにする-ノーマルモード',
  // device_param_setting_ai_hidden_2: 'AIをオンにする-サイレントモード',
  // 參數設定>工作日誌
  device_param_setting_log: '作業日誌',
  device_param_setting_log_keep_days: '保存日数',
  // 參數設定>人物
  device_param_setting_fr: '人物認識',
  // 參數設定>TW車牌
  device_param_setting_lprTw: 'TW車両認識',
  // 參數設定>JP車牌
  device_param_setting_lprJp: 'JP車両認識',
  // 參數設定>VN車牌
  device_param_setting_lprVn: 'VN車両認識',
  // 參數設定>物件
  device_param_setting_or: '物体認識',
  // save
  device_param_save_header: 'デバイスパラメータを編集',
  device_param_save_title: 'デバイスパラメータを編集してもよろしいですか？',

  // 日誌 =====================================================================
  log_access: 'アクセスログ',
  log_device: 'デバイスログ',
  log_resource: 'リソースログ',
  log_account_type: 'アカウント',
  log_account_type_user: 'ユーザー',
  log_account_type_device: 'デバイス',
  log_search_placeholder: 'アカウントを検索する',
  log_search_placeholder_user: 'ユーザーを検索する',
  log_search_placeholder_device: '検索デバイス',
  log_timestamp: '操作時間',
  log_operator: '操作者',
  log_operator_group: '操作グループ',
  log_ip: 'IP',
  log_content: '内容',
  log_target: '対象',
  log_device_account: 'デバイス',
  log_device_timestamp: '時間間隔',
  log_device_account_hint: 'デバイスを選択してください',
  log_device_tab_device: 'デバイス',
  log_device_tab_filename: 'ファイル名',
  log_device_tab_date: '日付',
  log_device_download_fail: 'ダウンロードに失敗しました',

  // codebook ----------------------------------------------------------------
  log_codebook_0: 'ログイン',
  log_codebook_1: 'ログイン成功',
  log_codebook_2: 'ログインに失敗しました',

  // 群組 ---
  log_codebook_100: 'グループ',
  log_codebook_101: 'グループの作成',
  log_codebook_102: 'グループの編集',
  log_codebook_103: 'グループの削除',
  // log_codebook_104: 'create_group_license',
  // log_codebook_105: 'edit_group_license',
  // log_codebook_106: 'delete_group_license',

  // 使用者 ---
  log_codebook_200: 'ユーザー',
  log_codebook_201: 'ユーザーの作成',
  log_codebook_202: 'ユーザーの編集',
  log_codebook_203: 'ユーザーの削除',
  // log_codebook_204: 'create_user_license',
  // log_codebook_205: 'edit_user_license',
  // log_codebook_206: 'delete_user_license',
  // log_codebook_207: 'verify_user_email',
  log_codebook_208: 'アカウントのロックを解除する',
  log_codebook_209: 'アカウントをロックする',

  // 設備 ---
  log_codebook_300: 'デバイス',
  log_codebook_301: 'デバイスの作成',
  log_codebook_302: 'デバイスの編集',
  log_codebook_303: 'デバイスの削除',

  // 伺服器 ---
  log_codebook_400: 'サーバ',
  log_codebook_401: 'サーバーの作成',
  log_codebook_402: 'サーバーの編集',
  log_codebook_403: 'サーバーの削除',

  // 發布 ---
  log_codebook_500: 'リリース',
  log_codebook_501: '正常なリリース',
  log_codebook_502: '異常なリリース',

  // 觀看 ---
  log_codebook_600: '視聴',
  log_codebook_601: '正常な視聴',
  log_codebook_602: '異常な視聴',

  // 歷史影像 ---
  log_codebook_700: '動画',
  log_codebook_701: '動画の作成',
  log_codebook_702: '動画の編集',
  log_codebook_703: '動画の削除',
  log_codebook_704: 'ビデオの再生が成功しました',
  log_codebook_705: 'ビデオの再生に失敗しました',
  log_codebook_706: 'ビデオのダウンロードが成功しました',
  log_codebook_707: 'ビデオのダウンロードに失敗しました',

  // // [legacy] Jager Eye config ---
  // log_codebook_800: 'Jager Eye',
  // log_codebook_801: 'create_jager_eye_config',
  // log_codebook_802: 'edit_jager_eye_config',
  // log_codebook_803: 'delete_jager_eye_config',
  // log_codebook_804: 'enable_jager_eye_config',
  // log_codebook_805: 'disable_jager_eye_config',
  // log_codebook_806: 'edit_jager_eye_event',
  // log_codebook_807: 'delete_jager_eye_evnet',

  // 人辨 ---
  log_codebook_900: '人物識別',
  log_codebook_901: 'ビデオの再生に失敗しましたの作成',
  log_codebook_902: 'ビデオの再生に失敗しましたの編集',
  log_codebook_903: 'ビデオの再生に失敗しましたの削除',

  // 設備控制 ---
  log_codebook_1000: 'デバイス制御',
  log_codebook_1001: 'デバイス制御に成功しました',
  log_codebook_1002: 'デバイス制御に失敗しました',

  // 遠端控制 ---
  log_codebook_1100: 'リモコン',
  log_codebook_1101: 'リモートコントロールに成功しました',
  log_codebook_1103: 'リモートコントロールに失敗しました',

  // 車牌辨識 ---
  log_codebook_1200: '車両識別',
  log_codebook_1201: 'ナンバープレート認識設定を追加',
  log_codebook_1202: 'ナンバープレート認識設定を編集',
  log_codebook_1203: 'ナンバープレート認識設定を削除',
  log_codebook_1204: '新しい認識車両を追加',
  log_codebook_1205: '識別された車両を編集',
  log_codebook_1206: '識別された車両を削除',
  log_codebook_1207: 'ナンバープレート認識スナップショットの編集',
  log_codebook_1208: 'ナンバープレート認識スナップショットの削除',
  log_codebook_1209: 'ナンバープレート認識イベントの編集',
  log_codebook_1210: 'ナンバープレート認識イベントの削除',
  log_codebook_1211: 'ナンバープレート認識マークの追加',
  log_codebook_1212: 'ナンバープレート認識マークの編集',
  log_codebook_1213: 'ナンバープレート認識マークの削除',

  // 角色 ---
  log_codebook_1300: '役割',
  log_codebook_1301: 'ユーザー役割の作成',
  log_codebook_1302: 'ユーザー役割の編集',
  log_codebook_1303: 'ユーザー役割の削除',

  // 物件辨識事件 ---
  log_codebook_1400: '物体識別',
  log_codebook_1401: 'オブジェクト認識イベントを編集',
  log_codebook_1402: 'オブジェクト認識イベントの削除',

  // 伺服器維護中
  construction_msg: 'サーバーメンテナンス中',

  // 憑證
  system_cert_ca_title: '認証局',
  system_cert_ca: '証明機関',
  system_cert_time: '時間',
  system_cert_enable_time: '有効時間',
  system_cert_expired_time: '有効期限',
  system_cert_update_time: '更新時間',
  system_cert_timestamp: 'タイムスタンプ',
  system_cert_fingerprint: '指紋',
  system_cert_fingerprint_encryption: '暗号化',
  system_cert_certificate: '証明書',
  system_cert_public_key: '公開鍵',
  system_cert_input_code: '更新するには検証コードを入力してください：',
  system_cert_file: '証明書ファイル',
  system_cert_private_key_file: '秘密鍵ファイル',
  system_cert_state_title: '証明書の更新',
  system_cert_state_check: '証明書の確認',
  system_cert_state_checking: '証明書の確認中',
  system_cert_state_checked: '証明書の確認',
  system_cert_state_check_failed: '憑證確認失敗',
  system_cert_state_updating: '証明書の更新中',
  system_cert_state_updated: '証明書が更新されました',
  system_cert_state_update_failed: '証明書の更新に失敗しました',
  system_cert_validate_code_error: '検証コードエラー',

  //--------------------------------------------
  // 停車場管理
  //--------------------------------------------
  parking_count: '駐車場カウンター',
  parking_filter_factory: '工場',
  parking_filter_parking: '駐車場',
  parking_tab_factory: '工場',
  parking_tab_parking: '駐車場',
  parking_tab_resetNum: '現在の車両数を設定します',
  parking_tab_resetTime: '時間を編集',
  parking_tab_nowNum: 'リアルタイム車両数',
  parking_tab_updateTime: '時間を更新',
  parking_list_error: '駐車場リストの取得に失敗しました',
  parking_list_edit: '車両数を編集',
}

// console.log(`ja:`, Object.keys(ja).length)

export default ja
