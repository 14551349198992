import i18n from '@/i18n/i18n'
import {
  apiCheckStatus,

  apiGetUserTree,
  apiGetGroupList,
  apiGetGroupInfoList,
  getRoles,
  apiGetUserInfoList as apiGetAccountList,
  apiGetDeviceModels, // 設備類型
  // LIVE+上線
  apiGetLiveList,
  apiGetConnections,
  apiGetServerList,
} from '@/api/index.js'
import { getGroupObj } from './func.js'
import { euAccountKind, userKindList } from '../../utils/global'
import { getDeviceModelIcon } from '@/config/account.js'
import { sortGroupTree } from '@/utils/lib.js'

const initialState = () => ({
  editMode: 'editUser', // multiSelect, editUser, addUser
  groupId: '',
  userTree: {},
  groupTreeList: [],
  groupList: [],
  groupInfoList: [],
  selectedGroupList: [], // 存放多選群組的帳列
  selectedUserList: [], // 存放多選帳號的陣列 {id, state：未設定(-1)/設定中(0)/成功(1)/失敗(2)}
  userId: '', // 選中的帳號Id
  roleList: [],
  userInfoList: [],
  filterText: '', // 搜尋帳號/帳號名稱
  filterRoles: [], // 過濾角色
  filterUseStates: [], // 過濾狀態：停用/啟用/暫停
  filterPublic: -1, // 過濾公開私有：全部(-1)/私有(0)/公開(1)
  filterUpdatedTime: [], // 過濾更新時間
  filterLastLogin: -1, // 過濾閒置時間
  filterResult: -1, // 篩選結果：沒觸發任何過濾(-1)
  multiSelectOnly: false, // 多選帳號 > 顯示勾選帳號
  accountPortal: '',
  addAccountGroupId: '', // 新增帳號時所在的群組

  deviceList: [],
  deviceInfoList: [],
  deviceModels: [], // 設備列表

  accInfoList: [], // 完整使用者＋設備

  onlineList: [], // LIVE||上線 設備
  srvLiveList: [], // 從 server/list 解析出來的 liveList
})

const state = initialState()

const mutations = {
  updateEditMode(state, payload) {
    state.editMode = payload
  },
  updateGroupId(state, payload) {
    state.groupId = payload
  },
  updateUserTree(state, payload) {
    state.userTree = payload
  },
  updateGroupTreeList(state, payload) {
    state.groupTreeList = payload
  },
  updateGroupList(state, payload) {
    state.groupList = payload
  },
  updateGroupInfoList(state, payload) {
    state.groupInfoList = payload
  },
  updateUserId(state, payload) {
    state.userId = payload
  },
  updateSelectedUserList(state, payload) {
    if (Array.isArray(payload)) {
      payload.forEach((item) => {
        if (state.selectedUserList.findIndex((user) => user.id === item.id) < 0)
          state.selectedUserList.push(item)
      })
    } else {
      const index = state.selectedUserList.findIndex(
        (user) => user.id === payload
      )
      if (index >= 0) state.selectedUserList.splice(index, 1)
      else state.selectedUserList.push(payload)
    }
  },
  updateSelectedUserState(state, user) {
    // 找不到就新增, 找到就更新
    const idx = state.selectedUserList.findIndex(({ id }) => id === user.id)
    if (user && idx < 0) state.selectedUserList.push({ id: user.id, state: -1 })
    else state.selectedUserList[idx].state = user.state
  },
  updateSelectedUser(state, userId) {
    // 找不到就新增, 找到就刪除
    let idx = state.selectedUserList.findIndex(({ id }) => id === userId)

    if (userId && idx === -1)
      state.selectedUserList.push({ id: userId, state: -1 })
    else state.selectedUserList.splice(idx, 1)
  },
  addSelectedUserList(state, list) {
    list.forEach((item) => {
      if (state.selectedUserList.findIndex((user) => user.id === item.id) < 0)
        state.selectedUserList.push({ id: item.id, state: -1 })
    })
  },
  removeSelectedUserList(state, list) {
    list.forEach((item) => {
      let idx = state.selectedUserList.findIndex((user) => user.id === item.id)
      if (idx >= 0) state.selectedUserList.splice(idx, 1)
    })
  },
  clearSelectedUserList(state) {
    state.selectedUserList = []
  },
  updateSelectedGroupList(state, grpId) {
    // 找不到就新增, 找到就刪除
    let idx = state.selectedGroupList.findIndex((id) => id === grpId)

    if (idx < 0) state.selectedGroupList.push(grpId)
    else state.selectedGroupList.splice(idx, 1)
  },
  clearSelectedGroupList(state) {
    state.selectedGroupList = []
  },
  updateFilterPublic(state, payload) {
    state.filterPublic = payload
  },
  updateFilterUpdatedTime(state, payload) {
    state.filterUpdatedTime = payload
  },
  updateFilterLastLogin(state, payload) {
    state.filterLastLogin = payload
  },
  updateFilterResult(state, payload) {
    state.filterResult = payload
  },
  updateRoleList(state, payload) {
    state.roleList = payload
  },
  updateUserInfoList(state, payload) {
    let sortUsers = payload.sort((a, b) => a.id.localeCompare(b.id, i18n.locale))
    state.userInfoList = sortUsers
  },
  updateFilterText(state, payload) {
    state.filterText = payload
  },
  updateFilterRoles(state, payload) {
    state.filterRoles = payload
  },
  cleanFilterRoles(state) {
    state.filterRoles = []
  },
  updateFilterUseStates(state, payload) {
    state.filterUseStates = payload
  },
  cleanFilterUseStates(state) {
    state.filterUseStates = []
  },
  updateMultiSelectOnly(state, payload) {
    state.multiSelectOnly = payload
  },
  updateAccountPortal(state, payload) {
    state.accountPortal = payload
  },
  updateAddAccountGroupId(state, payload) {
    state.addAccountGroupId = payload
  },

  // 設備管理
  // updateDeviceList(state, payload) {
  //   state.deviceList = payload
  // },
  updateDeviceInfoList(state, payload) {
    state.deviceInfoList = payload
  },
  updateDeviceModels(state, payload) {
    state.deviceModels = payload
  },
  updateAccInfoList(state, payload) {
    state.accInfoList = payload
  },

  // 群組管理
  updateOnlineList(state, payload) {
    state.onlineList = payload
  },
  updateSrvLiveList(state, payload) {
    state.srvLiveList = payload
  },
}

const actions = {
  async getUserTree({ commit, state }) {
    const res = await apiGetUserTree()
    commit('updateUserTree', res.data)
    // 再去取得groupTreeList
    if (Object.keys(state.userTree).length > 0) {
      const groupTreeList = [getGroupObj(state.userTree)]
      commit('updateGroupTreeList', sortGroupTree(groupTreeList))
    }
  },
  async getGroupList({ commit }) {
    let res = await apiGetGroupList()

    commit('updateGroupList', res.data)
    // if (res.data.length > 0)
    //   commit('updateGroupId', res.data[0].id)
  },
  async getGroupInfoList({ commit }) {
    let res = await apiGetGroupInfoList()
    commit('updateGroupInfoList', res.data)
  },
  async getRoleList({ commit }) {
    const res = await getRoles()
    commit('updateRoleList', res.data)
  },
  async getAccInfoList({ commit }) {
    try {
      const res = await apiGetAccountList()
      if (!apiCheckStatus(res)) throw res

      const accounts = res.data
      commit('updateAccInfoList', accounts)
    } catch (err) {
      console.error(`[Store.userinfo.getAccInfoList] fail`, err)
    }
  },
  async getUserInfoList({ state, commit }) {
    let raw = state.accInfoList

    // if (!state.accInfoList) { // 強制更新會拉不到東西
    const res = await apiGetAccountList()
    raw = res.data
    // }
    let users = raw.filter((data) => data?.kind != euAccountKind.device)

    commit('updateUserInfoList', users)
  },
  async getDeviceInfoList({ commit }) {
    let raw = state.accInfoList

    // if (!state.accInfoList) { // 強制更新會拉不到東西
    const res = await apiGetAccountList()
    raw = res.data
    // }

    let devices = raw.filter(
      (data) =>
        data?.kind === euAccountKind.default ||
        data?.kind === euAccountKind.device
    )
    commit('updateDeviceInfoList', devices)
  },
  async getDeviceModels({ commit }) {
    const res = await apiGetDeviceModels()
    if (!Array.isArray(res.data)) {
      commit('updateDeviceModels', [])
      return
    }
    const newDeviceModels = res.data.map((model) => {
      const { id, name } = model
      const icon = getDeviceModelIcon(id)

      // Note: API /device/models 的 id 是 String, userInfo.deviceModelId 是 Number
      // 前端統一成 Number, 會容易比對
      return { id: Number(id), name, icon }
    })
    commit('updateDeviceModels', newDeviceModels)
  },
  async getOnlineList(store) {
    try {
      const { commit, state } = store
      let list = []

      const liveRaw = await apiGetLiveList()
      if (liveRaw) list = [...list, ...liveRaw.data.liveList]

      const connectedRaw = await apiGetConnections()
      const connectedList = connectedRaw ? connectedRaw.data : []
      // 使用相同資料結構的資料
      const connectedAccInfoList = state.accInfoList.filter((_acc) =>
        connectedList
          .map((connected) => connected.userAccount)
          .includes(_acc.id)
      )
      // console.log(`[getOnlineList] connectedAccInfoList:`, connectedAccInfoList)
      list = [...list, ...connectedAccInfoList]

      // console.log(`[Store.getOnlineList] list:`, list)
      commit('updateOnlineList', list)
    } catch (err) {
      console.error(`[Store.getOnlineList] fail`, err)
    }
  },
  async getSrvLiveList({ commit }) {
    const list = []
    try {
      const res = await apiGetServerList()

      if (!apiCheckStatus(res)) throw res

      const sites = Object.keys(res.data)
      sites.forEach((siteName) => {
        const site_servers = Object.keys(res.data[siteName])
        site_servers.forEach((srvName) => {
          const server = res.data[siteName][srvName]
          if (server.service && Object.keys(server.service).length > 0) {
            const { live } = server.service['streamer-manager']
            live.forEach((liveAcc) => {
              list.push(liveAcc)
            })
          }
        })
      })
      // console.log(`[Store.userinfo.getSrvLiveList] list:`, list)
      commit('updateSrvLiveList', list)
    } catch (err) {
      console.error(`[Store.userinfo.getSrvLiveList] fail`, err)
      return list
    }
  },

  async fetchAccountManageList({ dispatch }) {
    // console.log(`[Store.userinfo.fetchAccountManageList]`)
    // 取得各列表
    await dispatch('getGroupList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getGroupList`)
    await dispatch('getGroupInfoList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getGroupInfoList`)
    await dispatch('getUserTree')
    // console.log(`[Store.userinfo.fetchAccountManageList] getUserTree`)
    await dispatch('getRoleList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getRoleList`)
    await dispatch('getDeviceModels')
    // console.log(`[Store.userinfo.fetchAccountManageList] getDeviceModels`)
    await dispatch('getAccInfoList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getAccInfoList`)

    await dispatch('getUserInfoList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getUserInfoList`)
    await dispatch('getDeviceInfoList')
    // console.log(`[Store.userinfo.fetchAccountManageList] getDeviceInfoList`)
  }
}

const getters = {
  groupUserList(state) {
    if (!state.groupId || state.userInfoList.length === 0) return []
    return state.userInfoList.filter((user) => user.groupId === state.groupId)
  },
  isUserChecked: (state) => (userId) => {
    const index = state.selectedUserList.findIndex((user) => user.id === userId)
    return index >= 0
  },
  getUserName: (state) => (userId) => {
    const user = state.userInfoList.find((item) => item.id === userId)
    return user ? user.info.name : ''
  },
  selectedUserInfo(state) {
    return state.userInfoList.find((item) => item.id === state.userId)
  },
  groupFamily: (state) => (groupId /*, topGroupId*/) => {
    const groupList = [...state.groupInfoList] // [...state.groupList]
    if (!groupId || groupList.length === 0) return []

    let list = []
    let parent = groupId
    let group = {}

    do {
      group = groupList.find((item) => item.id === parent)
      if (group) {
        parent = group.parent
        list.unshift({ id: group.id, name: group.name, maxIn: group.maxIn })
      }
    } while (group && Number(parent) > 0)

    // if (topGroupId) {
    //   const tmpList = JSON.parse(JSON.stringify(list))
    //   const topIdx = tmpList.findIndex((grp) => grp.id === topGroupId)
    //   list = tmpList.splice(topIdx)
    // }

    return list
  },
  getUserIcon: (state) => (userId) => {
    const user = state.userInfoList.find((user) => user.id === userId)

    if (!user || user.kind === 2) return ''

    let kind = null
    const kindMapList = userKindList
    const currKind = user?.kind

    kind = kindMapList.find(({ kind }) => {
      return currKind === kind
    })

    // 用預設
    if (!kind) {
      kind = kindMapList.find((kind) => !kind.key)
    }
    let authState = ''
    if (user.enabled === 0) {
      authState = '_disable'
    } else if (user.locked === 1) {
      authState = '_locked'
    } else if (user.suspendedTime) {
      authState = '_pause'
    }

    return require(`@/assets/icons/${kind.icon}${authState}.svg`)
  },
  getDeviceIcon: (state) => (deviceId) => {
    const device = state.deviceInfoList.find((user) => user.id === deviceId)
    if (!device) return ''

    let icon = getDeviceModelIcon(device.deviceModelId)
    let authState = ''
    if (state.deviceModels) {
      if (device.enabled === 0) {
        authState = '_disable'
      } else if (device.locked === 1) {
        authState = '_locked'
      } else if (device.suspendedTime) {
        authState = '_pause'
      }
    }

    // console.log(`[getDeviceIcon] authState:`, device.id, device.locked, authState)
    return require(`@/assets/icons/device_model_${icon}${authState}.svg`)
  },
  loggedUserInfo(state, getters, rootState, rootGetter) {
    // 依據目前登入者, 過慮帳號清單 => 取得登入者詳細資訊
    const logged = rootGetter.getUserInfo
    const fullUserList = state.userInfoList

    return fullUserList.find((userInfo) => userInfo.id === logged.id)
  },
  haveAllList(state) {
    const hasDeviceModels = state.deviceModels.length > 0

    const hasGroupList = state.groupList.length > 0
    const hasGroupInfoList = state.groupInfoList.length > 0

    const hasRoleList = state.roleList.length > 0

    const hasUserTree = Object.keys(state.userTree).length > 0
    const hasAccInfoList = state.accInfoList.length > 0
    const hasUserInfoList = state.userInfoList.length > 0
    const hasDeviceInfoList = state.deviceInfoList.length > 0

    // console.log(
    //   `[Store.userinfo.haveAllList] hasDeviceModels, hasGroupList, hasGroupInfoList, hasRoleList, hasUserTree `,
    //   hasDeviceModels,
    //   hasGroupList,
    //   hasGroupInfoList,
    //   hasRoleList,
    //   hasUserTree
    // )
    // console.log(
    //   `[Store.userinfo.haveAllList] hasAccInfoList, hasUserInfoList, hasDeviceInfoList `,
    //   hasAccInfoList,
    //   hasUserInfoList,
    //   hasDeviceInfoList
    // )

    const ret =
      // 設備類型
      hasDeviceModels &&
      // 群組
      hasGroupList &&
      hasGroupInfoList &&
      // 角色
      hasRoleList &&
      // 帳號
      hasUserTree &&
      hasAccInfoList &&
      hasUserInfoList &&
      hasDeviceInfoList

    // console.log(`[Store.userinfo.haveAllList] `, ret)
    return ret
  }
}

// 帳號管理-設備
const initDeviceState = () => ({
  // GroupTree
  groupId: '', // current groupId
  selectedGroupList: [], // 存放多選群組的帳列
  // Top
  editMode: 0, // multiSelect(for 批次設定), edit, add, delete(目前沒有 刪除設備的功能); ref: euEditMode
  filterText: '',
  filterDeviceModels: [], // 多選設備總類的陣列
  filterDeviceStates: [],
  filterPublic: -1, // 過濾公開私有：全部(-1)/私有(0)/公開(1)
  filterLinkMode: -1, // 過濾勾稽
  filterUpdatedTime: [], // 過濾更新時間
  filterLastLogin: -1, // 過濾閒置時間
  filterResult: -1, // 篩選結果：沒觸發任何過濾(-1)
  deviceId: '',
  addDeviceGroupId: '', // 新增帳號時所在的群組
  selectedDeviceList: [],
  multiSelectOnly: false,
})
const device = {
  namespaced: true,
  root: true,
  state: initDeviceState(),
  getters: {
    selectedDeviceInfo(state, getters, rootState) {
      const { deviceInfoList } = rootState.userinfo
      return deviceInfoList
        ? deviceInfoList.find((dev) => dev.id === state.deviceId)
        : {}
    }
    // typeList(state, getters, rootState, rootGetter) {
    //   console.log(`[typeList] deviceList:`, rootState.deviceList)
    //   return []
    // },
  },
  mutations: {
    updateUserInfoList(state, payload) {
      state.userInfoList = payload
    },
    updateGroupId(state, payload) {
      state.groupId = payload
    },
    updateSelectedGroupList(state, grpId) {
      // 找不到就新增, 找到就刪除
      let idx = state.selectedGroupList.findIndex((id) => id === grpId)

      if (idx < 0) state.selectedGroupList.push(grpId)
      else state.selectedGroupList.splice(idx, 1)
    },
    cleanSelectedGroupList(state) {
      state.selectedGroupList = []
    },
    updateEditMode(state, payload) {
      state.editMode = payload
    },
    updateFilterText(state, payload) {
      state.filterText = payload
    },
    updateFilterDeviceModels(state, payload) {
      state.filterDeviceModels = payload
    },
    cleanFilterDeviceModels(state) {
      state.filterDeviceModels = []
    },
    updateFilterDeviceStates(state, payload) {
      state.filterDeviceStates = payload
    },
    cleanFilterDeviceStates(state) {
      state.filterDeviceStates = []
    },
    updateFilterPublic(state, payload) {
      state.filterPublic = payload
    },
    updateFilterLinkMode(state, payload) {
      state.filterLinkMode = payload
    },
    updateFilterUpdatedTime(state, payload) {
      state.filterUpdatedTime = payload
    },
    updateFilterLastLogin(state, payload) {
      state.filterLastLogin = payload
    },
    updateFilterResult(state, payload) {
      state.filterResult = payload
    },
    updateSelectedDeviceState(state, dev) {
      // 找不到就新增, 找到就更新
      const idx = state.selectedDeviceList.findIndex(({ id }) => id === dev.id)
      if (dev && idx < 0)
        state.selectedDeviceList.push({ id: dev.id, state: -1 })
      else state.selectedDeviceList[idx].state = dev.state
    },
    updateDeviceId(state, payload) {
      state.deviceId = payload
    },
    addSelectedDeviceType(state, list) {
      list.forEach((item) => {
        if (!state.filterTypes.includes(item.id))
          state.filterTypes.push(item.id)
      })
    },
    delSelectedDeviceType(state, list) {
      list.forEach((item) => {
        const idx = state.filterTypes.findIndex((val) => val === item.id)
        if (idx >= 0) state.filterTypes.splice(idx, 1)
      })
    },
    updateSelectedDevice(state, deviceId) {
      const idx = state.selectedDeviceList.findIndex(
        (dev) => dev.id === deviceId
      )
      if (idx === -1) state.selectedDeviceList.push({ id: deviceId, state: -1 })
      else state.selectedDeviceList.splice(idx, 1)
    },
    addSelectedDeviceList(state, list) {
      list.forEach((item) => {
        if (
          state.selectedDeviceList.findIndex((dev) => dev.id === item.id) < 0
        ) {
          state.selectedDeviceList.push({ id: item.id, state: -1 })
        }
      })
    },
    removeSelectedDeviceList(state, list) {
      list.forEach((item) => {
        const idx = state.selectedDeviceList.findIndex(
          (dev) => dev.id === item.id
        )
        if (idx >= 0) state.selectedDeviceList.splice(idx, 1)
      })
    },
    updateSelectedDeviceList(state, payload) {
      if (Array.isArray(payload)) {
        payload.forEach((item) => {
          if (
            state.selectedDeviceList.findIndex((dev) => dev.id === item.id) < 0
          )
            state.selectedDeviceList.push(item)
        })
      } else {
        const index = state.selectedDeviceList.findIndex(
          (user) => user.id === payload
        )
        if (index >= 0) state.selectedDeviceList.splice(index, 1)
        else state.selectedDeviceList.push(payload)
      }
    },
    clearSelectedDeviceList(state) {
      state.selectedDeviceList = []
    },
    updateMultiSelectOnly(state, payload) {
      state.multiSelectOnly = payload
    },
    updateAddAccountGroupId(state, payload) {
      state.addDeviceGroupId = payload
    },
  }
  // actions: {},
}

// 帳號管理-群組
const group = {
  namespaced: true,
  root: true,
  state: () => ({
    filterText: '',
    editMode: 0, // none(0), add, editView, editing, ref: euEditMode
    groupId: '', // current groupId
    parentGroupId: '', // Belonged group
    editQuota: false,
  }),
  // getters: {
  //   groupList(state, getters, rootState) {
  //     const { groupList } = rootState.userinfo
  //     return (groupList) ? groupList : []
  //   },
  // },
  mutations: {
    updateFilterText(state, payload) {
      state.filterText = payload
    },
    updateGroupId(state, payload) {
      state.groupId = payload
    },
    updateEditMode(state, payload) {
      state.editMode = payload
    },
    updateParentGroupId(state, payload) {
      state.parentGroupId = payload.toString()
    },
    updateEditQuota(state, payload) {
      state.editQuota = payload
    },
  },
  actions: {}
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    // user,
    device,
    group
  }
}
