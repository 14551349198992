import { getDeepKeys } from '@/utils/lib'
//////////////////////////

export const configOr = {
  paramSource: {
    title: '$t_ai_param_source'/*參數來源*/,
    default: 1,
    options: [
      { label: '$t_ai_global_config'/*系統預設*/, value: 1 },
      { label: '$t_ai_user_config'/*設備客製*/, value: 0 },
    ],
    tooltip: '$t_ai_param_source_tip',
  },
  isEnabled: {
    title: '$t_ai_enable'/*啟用*/,
    default: 0,
    options: [
      { label: '$t_on'/*開啟*/, value: 1, icon: 'checked-green.svg', hintIcon: 'checked-gray.svg' },
      { label: '$t_off'/*關閉*/, value: 0, icon: 'Error.svg', hintIcon: 'Error-gray.svg' },
    ],
    tooltip: '$t_ai_enable_tip',
  },
  orMode: {
    title: '$t_ai_object_detect'/*物件偵測*/,
    default: 1,
    options: [
      { label: '$t_ai_object_detect'/*物件偵測*/, value: 1 },
      { label: '$t_ai_motion_detect'/*移動偵測*/, value: 2 },
    ],
    tooltip: '$t_ai_tooltip_mode',
  },
  repostDataStoreLimit: {
    title: '$t_ai_repost_data_store_limit'/*補傳事件保留上限*/,
    default: 1000,
    min: 100,
    max: 100000,
    step: 100,
    unit: '$t_ai_event_unit'/*筆*/,
    tooltip: '$t_ai_repost_data_store_limit_tip',
  },
  eventDuration: {
    title: '$t_ai_event_duration'/*重複事件間隔*/,
    default: 1,
    min: 1,
    max: 60,
    unit: '$t_min'/*分鐘*/,
    getUiValue: (val) => {
      return Math.round(val / 60)  // sec -> min 四捨五入到整數 / 只有aicam OR 才需秒&分鐘轉換
    },
    getSetValue: (val) => {
      return val * 60  // min -> sec
    },
    tooltip: '$t_ai_event_duration_tip',
  },
  repeatInterval: {
    title: '$t_ai_event_duration'/*重複事件間隔*/,
    default: 1,
    min: 1,
    max: 60,
    unit: '$t_min'/*分鐘*/,
    tooltip: '$t_ai_event_duration_tip',
  },
  personLevel: {
    title: '$t_ai_person_level'/*人*/,
    default: 3,
    min: 1,
    max: 5,
    label: {
      0: '$t_ai_level_0',
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    showCheckbox: true,
    prefixIcon: require('@/assets/icons/person.svg'),
    tooltip: '',
  },
  carLevel: {
    title: '$t_ai_car_level'/*汽車*/,
    default: 3,
    min: 1,
    max: 5,
    label: {
      0: '$t_ai_level_0',
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    showCheckbox: true,
    prefixIcon: require('@/assets/icons/car.svg'),
    tooltip: '',
  },
  bikeLevel: {
    title: '$t_ai_bike_level'/*二輪車*/,
    default: 3,
    min: 1,
    max: 5,
    label: {
      0: '$t_ai_level_0',
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    showCheckbox: true,
    prefixIcon: require('@/assets/icons/bicycle.svg'),
    tooltip: '',
  },
  truckLevel: {
    title: '$t_ai_truck_level'/*卡車*/,
    default: 3,
    min: 1,
    max: 5,
    label: {
      0: '$t_ai_level_0',
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    showCheckbox: true,
    prefixIcon: require('@/assets/icons/truck.svg'),
    tooltip: '',
  },
  busLevel: {
    title: '$t_ai_bus_level'/*公車*/,
    default: 3,
    min: 1,
    max: 5,
    label: {
      0: '$t_ai_level_0',
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    showCheckbox: true,
    prefixIcon: require('@/assets/icons/bus.svg'),
    tooltip: '',
  },
  recgFpsLevel: {
    title: '$t_ai_recg_fps_level'/*辨識頻率*/,
    default: 1,
    min: 1,
    max: 5,
    step: 1,
    label: {
      1: '$t_ai_level_1'/*最低*/,
      2: '$t_ai_level_2'/*低*/,
      3: '$t_ai_level_3'/*中*/,
      4: '$t_ai_level_4'/*高*/,
      5: '$t_ai_level_5'/*最高*/,
    },
    tooltip: '$t_ai_lpr_frequency_tip',
  },
  aiPerformance: {
    title: '$t_ai_recg_fps_level'/*辨識頻率*/,
    default: 0,
    min: 4,
    max: 0,
    step: -1,
    label: {
      4: '$t_ai_level_1'/*最低*/,
      3: '$t_ai_level_2'/*低*/,
      2: '$t_ai_level_3'/*中*/,
      1: '$t_ai_level_4'/*高*/,
      0: '$t_ai_level_5'/*最高*/,
    },
    tooltip: '$t_ai_lpr_frequency_tip',
  },
  staySec: {
    title: '$t_ai_stay_sec'/*停留秒數*/,
    default: 1,
    min: 0,
    max: 60,
    unit: '$t_sec'/*秒*/,
    tooltip: '$t_ai_tooltip_stay_sec',
  },
  motionLevel: {
    title: '$t_ai_motion_level'/*偵測靈敏度*/,
    default: 2,
    min: 1,
    max: 3,
    label: {
      1: '$t_ai_level_2'/*低*/,
      2: '$t_ai_level_3'/*中*/,
      3: '$t_ai_level_4'/*高*/,
    },
    tooltip: '',
  },
}

// 本機物件辨識設定
// ref json: https://bovia.com.tw/bitbucket/projects/OTH/repos/bovilive-app-settings/browse/android-AiCam_default_setting_130.json
const localChannel = {
  or: {
    isEnabled: 0,
    mode: 1,
    or: {
      personLevel: 3,
      carLevel: 3,
      bikeLevel: 3,
      truckLevel: 3,
      busLevel: 3,
      recgFpsLevel: 3,
      staySec: 1
    },
    md: {
      motionLevel: 2,
      recgFpsLevel: 3,
      staySec: 1
    },
    roi: {
      x1: 0.0,
      y1: 0.0,
      x2: 1.0,
      y2: 1.0
    },
    area1: [],
    area2: [],
    area3: []
  }
}

const localDeviceConfig = {
  or: {
    repostDataStoreLimit: 2000,
    or: {
      eventDuration: 60
    },
    md: {
      eventDuration: 60
    }
  }
}
  
/**
 * 設定預設值：若沒有設定，則使用預設值
 * @param {*} setting
 */
export const setOrDefaultValue = (setting, refSetting = {}) => {
  const origKeys = getDeepKeys(setting)
  
  // check deviceConfig
  const deviceConfig = setting.deviceConfig
  mergeDefaults(deviceConfig.or, localDeviceConfig.or, refSetting?.deviceConfig?.or)

  // check channel or data
  setting.channel.forEach((channel, index) => {
    // check channel.aiPerformance
    if (channel.aiPerformance === undefined) {
      channel.aiPerformance = refSetting?.channel?.[index]?.aiPerformance || configOr.aiPerformance.default
    }
    mergeDefaults(channel.or, localChannel.or, refSetting?.channel?.[index]?.or)
  })

  const newKeys = getDeepKeys(setting)
  const missingTags = newKeys.filter(key => !origKeys.includes(key))
  
  return missingTags
}

// 檢查物件中每個屬性，若有缺少將預設值合併到目標物件中
// 若 targetObj 有缺少，用 defaultObj 填補，若 defaultObj 也無，則用 localObj 填補
export function mergeDefaults(targetObj, localObj, defaultObj) {
  for (const key in localObj) {
    if (typeof localObj[key] === 'object' && localObj[key] !== null) {
      if (!targetObj[key]) targetObj[key] = {}
      mergeDefaults(targetObj[key], localObj[key], defaultObj?.[key])
    } else if (!(key in targetObj)) {
      targetObj[key] = defaultObj?.[key] ? defaultObj[key] : localObj[key]
    }
  }
}

export const orIcon = {
  1: require('@/assets/icons/person.svg'),
  2: require('@/assets/icons/car.svg'),
  3: require('@/assets/icons/bicycle.svg'),
  4: require('@/assets/icons/truck.svg'),
  5: require('@/assets/icons/bus.svg'),
}