import i18n from '../i18n/i18n'

// 判斷是否為中文字符的正則表達式
const isChinese = (str) => /^[\u4e00-\u9fa5]+$/.test(str)

// 排序規則
// 資料格式: [{ name: 'xxx' }, { name: 'yyy' }]
// 0. 忽略前後空白
// 1. 先中文
// 2. 再英文, 先大寫再小寫
export const compareRule = (a, b, key = null) => {
  const aIsChinese = isChinese(key ? a[key].name.trim() : a.name.trim())
  const bIsChinese = isChinese(key ? b[key].name.trim() : b.name.trim())
  // 中文優先
  if (aIsChinese && !bIsChinese) {
    return -1 // 中文在前
  } else if (!aIsChinese && bIsChinese) {
    return 1 // 非中文在後
  } else if (aIsChinese && bIsChinese) {
    // 如果都是中文，按字典順序排序
    return key
      ? a[key].name.trim().localeCompare(b[key].name.trim(), i18n.locale)
      : a.name.trim().localeCompare(b.name.trim(), i18n.locale)
  } else {
    // 如果都是英文，先大寫再小寫
    const aIsUpperCase = /^[A-Z]/.test(key ? a[key].name.trim() : a.name.trim())
    const bIsUpperCase = /^[A-Z]/.test(key ? b[key].name.trim() : b.name.trim())
    const bSameAlpha = key
      ? a[key].name.trim().toLowerCase() === b[key].name.trim().toLowerCase()
      : a.name.trim().toLowerCase() === b.name.trim().toLowerCase()
    if (bSameAlpha) {
      if (aIsUpperCase && !bIsUpperCase) {
        return -1 // 大寫在前
      } else if (!aIsUpperCase && bIsUpperCase) {
        return 1 // 小寫在後
      }
    } else {
      // 忽略大小寫進行字母排序
      return key
        ? a[key].name.trim().localeCompare(b[key].name.trim(), i18n.locale)
        : a.name.trim().localeCompare(b.name.trim(), i18n.locale)
    }
  }
}
