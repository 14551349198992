import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { 
  apiGetBoviaOrEvents,
  apiGetBoviaOrEventsCount
} from '@/api/index.js'
import crypto from '@/utils/crypto'
import { handleError } from '@/utils/handleError.js'
  
const initialState = () => ({
  // 暫存最新三筆案由於localStorage
  queryCauses: localStorage.getItem('queryCausesOr') 
    ? JSON.parse(crypto.decrypt(localStorage.getItem('queryCausesOr'))) : [], // 案由
  searchLoading: false,
  orPageSize: 100, // 事件列表每個分頁事件數量
  orEventsTotal: 0, // 搜尋的總事件數
  orCurrentPage: 1, // 事件列表目前分頁所在頁數，從1開始
  orCurrentIndex: 0, // 事件在事件列表目前分頁的index, 從0開始
  orFocusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  orEventList: [], // 僅存放該次搜尋的事件ex:100筆
  queryParams: {}, // 取得歷史人物事件參數
  beforeEventId: null, // 記錄目前分頁before event id, 為該分頁最小的id
  afterEventId: null, // 記錄目前分頁after event id, 為該分頁最大的id

  objsClassCount: {}, // 取得事件的detectObjs的class count
  detectAreas: {}, // 取得事件的detectAreas
})
  
const state = initialState()

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  addQueryCause(state, cause) {
    let idx = state.queryCauses.findIndex(item => item === cause.trim())
    if (idx === -1) {
      state.queryCauses.unshift(cause.trim())
      state.queryCauses = state.queryCauses.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryCausesOr', crypto.encrypt(JSON.stringify(state.queryCauses)))
    }
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateOrPageSize(state, value) {
    state.orPageSize = value
  },
  updateOrEventsTotal(state, value) {
    state.orEventsTotal = value
  },
  updateOrCurrentPage(state, value) {
    state.orCurrentPage = value
  },
  updateOrCurrentIndex(state, value) {
    state.orCurrentIndex = value
  },
  updateOrFocusIndex(state, value) {
    state.orFocusIndex = value
  },
  updateOrEventList(state, list) {
    state.orEventList = list
    // add uid for each event
    state.orEventList.forEach(event => {
      event.uid = `or-${event.id}`
    })
  },
  updateQueryParams(state, payload) {
    state.queryParams = payload
  },
  updateBeforeEventId(state, value) {
    state.beforeEventId = value
  },
  updateAfterEventId(state, value) {
    state.afterEventId = value
  },
  updateObjsClassCount(state, value) {
    state.objsClassCount = value
  },
  updateDetectAreas(state, value) {
    state.detectAreas = value
  },
}

const actions = {
  async getOrEventsTotal({ commit, state }) {
    try {
      const res = await apiGetBoviaOrEventsCount(state.queryParams)
      commit('updateOrEventsTotal', res.data.total)
    } catch (err) {
      console.log('apiGetBoviaOrEventsCount error: ', err)
      commit('updateOrEventsTotal', 0)
    }
  },
  async searchOrEvents({ commit, state, dispatch }, payload) {
    commit('updateSearchLoading', true)
    if (state.queryParams.purpose)
      commit('addQueryCause', state.queryParams.purpose) // 將案由暫存至localStorage
    
    const params = { ...state.queryParams, limit: state.orPageSize }
    let bGoPrev = false // 是否回到前一分頁
    if (payload.page !== 1) {
      if (payload.page > state.orCurrentPage) {
        delete params.after
        params.before = state.beforeEventId
      } else {
        delete params.before
        params.after = state.afterEventId
        bGoPrev = true
      }  
    }

    // 取得事件
    try {
      const res = await apiGetBoviaOrEvents(params)
      const len = res.data?.length
      if (len > 0) {
        // 若用after取資料id會從小到大，須先排序
        res.data.sort((x, y) => y.id - x.id)
        // 資料id由大到小
        commit('updateOrEventList', res.data)
        commit('updateBeforeEventId', res.data[len - 1].id)
        commit('updateAfterEventId', res.data[0].id)
        commit('updateOrCurrentPage', payload.page)

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = bGoPrev ? len - 1 : 0 
        commit('updateOrCurrentIndex', eventIndex)
        commit('updateOrFocusIndex', eventIndex)
      } else {
        commit('updateOrEventList', [])
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
    } catch (error) {
      handleError(error)
      dispatch('resetHistoryOrData')
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  getObjsClassCount({commit}, payload) {
    const arrCls = payload.map(item => item.class).filter((v, i, a) => a.indexOf(v) === i)
    
    const clsCount = {}
    arrCls.forEach(cls => {
      let count = 0
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].class === cls)
          count++
      }
      clsCount[cls] = { 
        count,
        isShow: true
      }
    })
    commit('updateObjsClassCount', clsCount)
  },
  getDetectAreas({ commit }, payload) {
    const areas = Object.keys(payload)
    const detAreas = {}
    areas.forEach(area => {
      if (payload[area].length > 0)
        detAreas[area] = {
          roi: payload[area], 
          isShow: true
        }
    })
    commit('updateDetectAreas', detAreas)
  },
  resetHistoryOrData({ commit }) {
    commit('updateOrEventList', [])
    commit('updateOrEventsTotal', 0) // 搜尋的總事件數
    commit('updateOrCurrentPage', 1) // 事件列表目前分頁所在頁數
    commit('updateOrCurrentIndex', 0) // 事件在事件列表目前分頁的index
    commit('updateOrFocusIndex', -1) // focus事件在事件列表目前分頁的index
    commit('updateObjsClassCount', {}) // 取得事件的detectObjs的class count
    commit('updateDetectAreas', {}) // 取得事件的detectAreas
  },
}
const getters = {
  orModalEvent(state) {
    return state.orEventList[state.orCurrentIndex]
  },
  totalOrEventNo(state) {
    // 目前事件在所有事件的編號，從１開始
    return (state.orCurrentPage - 1) * state.orPageSize + state.orCurrentIndex + 1
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}