import apiClient from './index'
import { apiGet, apiPost, apiPut, apiDelete } from './index'
import { sortAccount } from '@/utils/lib.js'

/**
  kind: device => 只能看到設備, 
  kind: user => 只能看到使用者, 
  不帶kind：可以看到所有使用者和設備
  enabled: 0 => 只能看到停用的設備
  enabled: 1 => 只能看到啟用的設備
  不帶 enabled: 可以看到所有設備
  public: 1 => 只能看到公開設備
  public: 0 => 可視範圍內的設備包含可視範圍內的公開設備
  不帶public: 可視範圍內的設備＋所有公開設備
  dashboard: L1 => public:0 可視範圍內的設備包含可視範圍內的公開設備
  dashboard: L2 => 不帶public: 私有設備＋所有公開設備
 */
export async function apiGetUserList({ kind, enabled, public: pub }) {
  // let url = ''
  // if (kind === 'device') url = '/api/v4/user/list?kinds=0&kinds=2'
  // else if (kind === 'user') url = '/api/v4/user/list?kinds=0&kinds=1&kinds=3'
  // else url = '/api/v4/user/list' // 導覽欄 > 通訊錄 (人&設備都要顯示)
  // if (enabled !== undefined && enabled !== null) 
  //   url += (!kind ? `?` : `&`) + `enabled=${enabled}`
  // if (pub !== undefined && pub !== null) 
  //   url += (!kind && (enabled === undefined || enabled === null) ? 
  //     `?` : `&`) + `public=${pub}`
  // return apiClient.get(url)

  let data = {}

  if (kind === 'device') {
    data.kinds = ['0', '2']
  } else if (kind === 'user') {
    data.kinds = ['0', '1', '3']
  } // else 導覽欄 > 通訊錄 (人&設備都要顯示)

  if (enabled !== undefined && enabled !== null) {
    data.enabled = enabled
  }

  if (pub !== undefined && pub !== null) {
    data.public = pub
  }

  const res = await apiGet('/api/v4/user/list', data)
  res.data = sortAccount(res.data)
  return res
}

export function apiVideoSearch(data) {
  return apiClient.post(`/api/v4/video/search`, data)
}

export function apiVideoCount(data) {
  return apiClient.post(`/api/v4/video/count`, data)
}

/**---------------------------------------------------------------
 * AI Box API
 ---------------------------------------------------------------*/ 
/**
 * 
 * @param {string} aiParam Optional. (lpr/or) 
 * @returns 
 */ 
export function apiGetAiBoxes(aiParam) {
  if (aiParam) return apiClient.get(`/api/v4/aiboxes?ai=${aiParam}`)
  else return apiClient.get(`/api/v4/aiboxes`)
}

export async function apiDeleteAiBox(aiBoxId) {
  // Note: 後台沒有 api
  return await apiDelete(`/api/v4/aibox`, { data: { id: aiBoxId } })
}

/**
 * Get AI Box Tasks
 * @param {string} sourceId 
 * @returns 
 */
export function apiGetAiBoxTasks(sourceId) {
  return apiClient.get(`/api/v4/aibox/tasks?sourceId=${sourceId}`)
}

/**
 * Post AI Box Task
 * @param {*} data 
 * @returns 
 */
export function apiPostAiBoxTask(data) {
  return apiClient.post(`/api/v4/aibox/task`, data)
}

/**
 * Put AI Box Task
 * @param {*} data 
 * @returns 
 */
export function apiPutAiBoxTask(data) {
  return apiClient.put(`/api/v4/aibox/task`, data)
}

/**
 * Delete AI Box Task
 * @param {string} id 
 * @returns 
 */
export function apiDeleteAiBoxTask(id) {
  return apiClient.delete(`/api/v4/aibox/task`, { data: { id: id } })
}

/**
 * Post AI Box Task Subscriber
 * @param {string} taskId 
 * @param {string} userId 
 * @returns 
 */
export function apiPostAiBoxTaskSubscriber(taskId, userId) {
  return apiClient.post(`/api/v4/aibox/task/subscriber`, { taskId: taskId, userId: userId })
}

/**
 * Delete AI Box Task Subscriber
 * @param {string} taskId 
 * @param {string} userId 
 * @returns 
 */
export function apiDeleteAiBoxTaskSubscriber(taskId, userId) {
  return apiClient.delete(`/api/v4/aibox/task/subscriber`, { data: { taskId: taskId, userId: userId } })
}

/**
 * Test AIBox Task Subscriber
 * @param {string} taskId
 * @param {string} userId
 * @returns 204
 */
export function apiAiBoxTaskSubscriberTest(taskId, userId) {
  return apiClient.post(`/api/v4/aibox/task/subscriber/test`, { taskId, userId })
}

/*--------------------------------------------*/
// Device AI Setting
/*--------------------------------------------*/
// 取得設備訂閱者
// model => LPR: 1, OR: 2, FR: 3
export function apiGetUserSubscribers(userId, model) {
  return apiClient.get(`/api/v4/user/subscribers?userId=${userId}&model=${model}`)
}

// 新增設備訂閱者
export function apiPostUserSubscriber(data) {
  return apiClient.post(`/api/v4/user/subscriber`, data)
}

// 刪除設備訂閱者
export function apiDeleteUserSubscriber(data) {
  return apiClient.delete(`/api/v4/user/subscriber`, { data: data })
}

// 發送設備訂閱者測試通知
export function apiUserSubscriberTest(userId, subscriberId, model) {
  return apiClient.post(`/api/v4/user/subscriber/test`, { userId, subscriberId, model })
}

/*--------------------------------------------*/
// 本機辨識 Line Notify 設定
/*--------------------------------------------*/
export function apiGetUserWebhooks(params) {
  return apiGet(`/api/v4/user/webhooks`, params)
}

export function apiGetUserWebhook(id) {
  return apiGet(`/api/v4/user/webhook?id=${id}`)
}

export function apiCreateUserWebhook(data) {
  return apiPost(`/api/v4/user/webhook`, data)
}

export function apiEditUserWebhook(data) {
  return apiPut(`/api/v4/user/webhook`, data)
}

export function apiDeleteUserWebhook(data) {
  return apiDelete(`/api/v4/user/webhook`, { data })
}

export function apiUserWebhookTest(data) {
  return apiPost(`/api/v4/user/webhook/test`, data)
}

/*--------------------------------------------*/
// AiBox Line Notify 設定
/*--------------------------------------------*/
export function apiGetAiboxTaskWebhooks(params) {
  return apiGet(`/api/v4/aibox/task/webhooks`, params)
}

export function apiGetAiboxTaskWebhook(id) {
  return apiGet(`/api/v4/aibox/task/webhook?id=${id}`)
}

export function apiCreateAiboxTaskWebhook(data) {
  return apiPost(`/api/v4/aibox/task/webhook`, data)
}

export function apiEditAiboxTaskWebhook(data) {
  return apiPut(`/api/v4/aibox/task/webhook`, data)
}

export function apiDeleteAiboxTaskWebhook(data) {
  return apiDelete(`/api/v4/aibox/task/webhook`, { data })
}

export function apiAiboxTaskWebhookTest(data) {
  return apiPost(`/api/v4/aibox/task/webhook/test`, data)
}

/*--------------------------------------------*/
// Calling API, 通話
/*--------------------------------------------*/
export function apiGetWebRTCRooms() {
  // return {
  //   updateTick: https://www.epochconverter.com/,
  //   rooms: []
  // }
  return apiGet(`/api/v4/webrtc/rooms`)
}
export function apiGetWebsocketList() {
  // return {
  //   updateTick: https://www.epochconverter.com/,
  //   rooms: []
  // }
  return apiGet(`/api/v4/websocket/list`)
}
