// import { isDevMode } from '@/utils/lib.js'
import { forced2faOptions } from '@/config/others.js'
import { euAccountKind } from '@/utils/global.js'
import { DefaultTopScope } from '@/config/permissions.js'

// 帳號編輯模式
export const euEditMode = {
  none: 0,
  add: 1,
  multiSelect: 2,
  editView: 3,
  editing: 4,
  // delete: 5,
}

// 帳號使用狀態
export const euUsedState = {
  disable: 0,
  enabled: 1,
  locked: 2
}
export const enabledOptions = Object.keys(euUsedState)
  .filter((key) => euUsedState[key] < euUsedState.locked) // 因為未有需求, 暫不實作批量設定 locked
  .map((key) => {
    return { label: `account_status_${key}`, value: euUsedState[key] }
  })

// 帳號公開/私有
export const euPublic = {
  public: 1, // 因應後台來的資料, 只有 0/1
  private: 0,
}
export const publicOptions = Object.keys(euPublic).map((key) => {
  const value = euPublic[key]
  return { label: `account_public_${value}`, value }
})

// 帳號類型
export const userKindOptions = Object.keys(euAccountKind)
  .filter((key) => euAccountKind[key] === euAccountKind.user)
  .map((key) => {
    return { label: `account_kind_${key}`, value: euAccountKind[key] }
  })
export const deviceKindOptions = Object.keys(euAccountKind)
  .filter((key) => euAccountKind[key] === euAccountKind.device)
  .map((key) => {
    return { label: `account_kind_${key}`, value: euAccountKind[key] }
  })

// 帳號勾稽
export const euLinkMode = {
  not_link: 0, // 不勾稽
  bovilive: 1, // BoviLive, 不強制
  bovilive_force: 2, // BoviLive, 強制
  npa_sso: 3, // NPA, 不強制
  npa_sso_force: 4 // NPA, 強制
}
export const linkedModeOptions = Object.keys(euLinkMode).map((linkMode) => {
  const value = euLinkMode[linkMode]
  return {
    label: `account_linkmode_${value}`,
    value,
  }
})

// 參數設定
export const euDefaultSetting = {
  global: 1, // default
  user: 0
}
export const defaultSettingOptions = Object.keys(euDefaultSetting).map(
  (setting) => {
    const value = euDefaultSetting[setting]
    return {
      label: `account_defaultSetting_${value}`,
      value
    }
})

// 浮水印
export const euWaterMark = {
  on: 1,
  off: 0, // default
}
export const watermarkOptions = Object.keys(euWaterMark).map((onOff) => {
  const value = euWaterMark[onOff]
  return {
    label: `account_watermark_${value}`,
    value
  }
})

// 停車管理, 入口(0) / 出口(1) / APP=無(2)
export const euParkDirection = {
  enter: 0,
  exit: 1,
  app: 2,
}
export const parkDirectOptions = Object.keys(euParkDirection).map((direct) => {
  const value = euParkDirection[direct]
  return {
    label: `account_note_park_direction_${value}`,
    value
  }
})
// 停車管理, 車種 : 汽車(3) / 機車(4)
export const euParkVehicleType = {
  car: 3,
  motorcycle: 4
}
export const parkVehicleTypeOptions = Object.keys(euParkVehicleType).map(
  (type) => {
    const value = euParkVehicleType[type]
    return {
      label: `account_note_park_vehicle_type_${value}`,
      value
    }
  }
)

export function parseNotePark(value) {
  try {
    let park = {
      direction: null,
      vehicleType: null
    }
    const tmpValue = String(value)
    if (!tmpValue) return park

    const notes = tmpValue.split('')
    const directions = Object.values(euParkDirection).map((_v) => String(_v))
    const vehicleTypes = Object.values(euParkVehicleType).map((_v) =>
      String(_v)
    )

    if (notes.length === 1) {
      const note = notes.shift()
      if (directions.includes(note)) {
        park = {
          ...park,
          ...{ direction: Number(note) }
        }
      } else if (vehicleTypes.includes(note)) {
        park = {
          ...park,
          ...{ vehicleType: Number(note) }
        }
      }
    } else if (notes.length === 2) {
      const [vehicleType, direction] = notes.map((_n) => Number(_n))
      park = {
        direction,
        vehicleType
      }
    }

    return park
  } catch (err) {
    console.error(`[parseNotePark] err`, err)
  }

  return value
}

// -----------------------------------------------------------------------------
// 帳號與權限：帳號各狀態
export const all = -1

// 公開狀態
const euPublicSt = {
  all,
  public: euPublic.public, // 因應後台來的資料, 只有 0/1
  private: euPublic.private
}
const publicSt = {
  key: 'public',
  options: Object.keys(euPublicSt).map((option) => {
    return {
      label: option,
      value: euPublicSt[option],
      str: euPublicSt[option] === all ? option : euPublicSt[option]
    }
  }),
  value: euPublicSt.all
}

// 勾稽
const euLinkModeSt = {
  all,
  bovilive_force: euLinkMode.bovilive_force, // BoviLive, 強制
  bovilive: euLinkMode.bovilive, // BoviLive, 不強制
  npa_sso_force: euLinkMode.npa_sso_force, // NPA, 強制
  npa_sso: euLinkMode.npa_sso, // NPA, 不強制
  not_link: euLinkMode.not_link // 不勾稽
}
const linkModeSt = {
  key: 'linkmode',
  options: Object.keys(euLinkModeSt).map((option) => {
    return {
      label: option,
      value: euLinkModeSt[option],
      str: euLinkModeSt[option] === all ? option : euLinkModeSt[option]
    }
  }),
  value: euPublicSt.all
}

// 更新時間
const updatedTimeSt = {
  key: `updatedTime`,
  options: null,
  value: [],
  ui: 'date'
}

// 閒置時間
const euIdleTimeSt = {
  all, // -1
  d30: 30,
  // d60: 60,
  d90: 90,
  // d120: 120,
  d180: 180,
}
const idleTimeSt = {
  key: 'lastLogin',
  options: Object.keys(euIdleTimeSt).map((option) => {
    return {
      label: option,
      value: euIdleTimeSt[option],
      str: euIdleTimeSt[option] === all ? option : euIdleTimeSt[option]
    }
  }),
  value: euIdleTimeSt.all,
  // ui: null
}

export const userFilters = [publicSt, updatedTimeSt, idleTimeSt]
export const deviceFilters = [publicSt, linkModeSt, updatedTimeSt, idleTimeSt]

export const getGpsStr = (lat, lng) => {
  if (lat && lng) {
    return `${lat.toFixed(7)},${lng.toFixed(7)}`
  } else if (!lat && lng) {
    return `0,${lng.toFixed(7)}`
  } else if (lat && !lng) {
    return `${lat.toFixed(7)},0`
  } else {
    return `0,0`
  }
}

export const SuspendTimeList = [null, 5, 15, 30, 60]

// 設備類型：DeviceModelId -------------------------------------------------------
export const euDeviceModelId = {
  BoviCam_BZ: 10,
  BoviCam_S: 20,
  BoviCam_U: 30,
  BoviCam_P: 40,
  BoviCam_X: 50,
  AiCam_BZ: 100,
  AiCam_S: 110,
  AiCam_U: 120,
  AiCam_BF: 130,
  AiCam_BFV: 131,
  AiCam_X: 140,
  BWC_W240: 200,
  HTC_5G: 210,
  BWC_W250: 220,
  FaceArk_XX: 500,
  BoviCast_Android: 1000,
  BoviCast_FR: 2000,
  BoviCast_iOS: 2010,
  Patrol_Car: 2100,
  Patrol_Car_LPR: 2110,
  Patrol_Moto: 2200,
  Patrol_Moto_LPR: 2210,
  Patrol_Moto_Pro: 2220,
  Mac_Patrol: 3000, // 保留
  VirtualCam: 4000,
}
export const ptzDeviceModelId = [
  euDeviceModelId.BoviCam_BZ,
  euDeviceModelId.BoviCam_S,
  euDeviceModelId.AiCam_BZ,
  euDeviceModelId.AiCam_S,
  euDeviceModelId.BoviCast_FR,
  euDeviceModelId.BoviCast_iOS,
]
// 對應 DB: default_settings.device_model_id 是否有支援
export const patrolDeviceModelId = [
  euDeviceModelId.Patrol_Car,
  euDeviceModelId.Patrol_Car_LPR,
  euDeviceModelId.Patrol_Moto,
  euDeviceModelId.Patrol_Moto_LPR,
  euDeviceModelId.Patrol_Moto_Pro
]
export const bovicastDeviceModelId = [
  // euDeviceModelId.BoviCast_Android, // 尚未支援 device default_setting
  euDeviceModelId.BoviCast_FR,
  euDeviceModelId.BoviCast_iOS
]
export const aicamDeviceModelId = [
  euDeviceModelId.AiCam_BZ,
  euDeviceModelId.AiCam_S,
  euDeviceModelId.AiCam_U,
  euDeviceModelId.AiCam_BF,
  euDeviceModelId.AiCam_BFV,
  euDeviceModelId.AiCam_X,
]
export const virtualDeviceModelId = [
  euDeviceModelId.VirtualCam,
]

export const getDeviceModelIcon = (id) => {
  let icon = id

  // 處理共用 icon
  switch (Number(id)) {
    case euDeviceModelId.BoviCam_BZ: // BoviCam-BZ (箱型PTZ)
    case euDeviceModelId.BoviCam_S: // BoviCam-S (球型PTZ)
    case euDeviceModelId.BoviCam_U: // BoviCam-U (微型)
    case euDeviceModelId.BoviCam_P: // BoviCam-P (HDMI型)
    case euDeviceModelId.BoviCam_X: // BoviCam-X (偽裝型)
    case euDeviceModelId.AiCam_BZ: // AiCam-BZ (箱型PTZ)
    case euDeviceModelId.AiCam_S: // AiCam-S (球型PTZ)
    case euDeviceModelId.AiCam_U: // AiCam-U (微型)
    case euDeviceModelId.AiCam_BF: // AiCam-BF (箱型)
    case euDeviceModelId.AiCam_BFV: // AiCam-BFV (箱型) 分貝計
    case euDeviceModelId.AiCam_X: // AiCam-X (偽裝型)
    case euDeviceModelId.FaceArk_XX: // FaceArk-XX (FaceArk)
    case euDeviceModelId.VirtualCam: // 虛擬攝影機
      break;
    case euDeviceModelId.Patrol_Car: // Patrol-Car, 有 SOS
    case euDeviceModelId.Patrol_Car_LPR: // Patrol-Car LPR only
      icon = '2100'
      break;
    case euDeviceModelId.Patrol_Moto: // Patrol-Moto, 有 SOS
    case euDeviceModelId.Patrol_Moto_LPR: // Patrol-Moto LPR only
    case euDeviceModelId.Patrol_Moto_Pro: // Patrol-Moto Pro (iOS)
      icon = '2200'
      break
    case euDeviceModelId.BWC_W240: // BWC-W240 (穿戴式-觸控款), 有 SOS
    case euDeviceModelId.HTC_5G: // (穿戴式-觸控款5G)
    case euDeviceModelId.BWC_W250: // BWC-W250 (穿戴式-OLED)
      icon = `200`
      break
    case euDeviceModelId.BoviCast_Android: // BoviCast (Android), 有 SOS
    case euDeviceModelId.BoviCast_FR: // BoviCast FR (iOS), 有 SOS
    case euDeviceModelId.BoviCast_iOS: // BoviCast (iOS), 有 SOS
      icon = `2000`
      break
    case euDeviceModelId.Mac_Patrol: // Mac Patrol
    default:
      icon = '0' // 有 SOS
  }

  return icon
}

// -----------------------------------------------------------------------------

// 帳號與權限：詳細欄位
const accountItems = [
  // {
  //   key,
  //   title,
  //   forUserCreate,
  //   forUser,
  //   forDeviceCreate,
  //   forDevice,
  //   forDeviceMulti,
  //   value,
  //   editValue,
  //   canEdit,
  //   required,
  //   ui,
  //   options, //ui === 'dropdown' 才會生效
  //   icon,
  //   helper,
  // },
  {
    key: 'enabled',
    title: 'enabled' /*啟用*/,
    // forUserCreate: true,
    // forUser: false,
    forUserMulti: true,
    // forDeviceCreate: true,
    // forDevice: false,
    forDeviceMulti: true,
    ui: 'dropdown',
    options: enabledOptions,
    icon: require(`@/assets/icons/check-square.svg`)
  },
  // 群組與帳號
  {
    title: 'account_detail_group_accound' /*群組與帳號*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false
  },
  {
    key: 'group',
    title: 'account_detail_group' /*群組*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'tree',
    icon: require(`@/assets/icons/Group.svg`)
  },
  {
    key: 'id',
    title: 'account_detail_id' /*ID 帳號*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'text',
    icon: require(`@/assets/icons/id.svg`)
  },
  // 密碼
  {
    title: 'account_detail_password' /*密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
  },
  {
    title: 'account_device_detail_password' /*授權碼*/,
    forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
  },
  {
    key: 'password',
    title: 'account_password' /*密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  {
    key: 'password',
    title: 'account_device_password' /*授權碼*/,
    forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  {
    key: 'password_confirm',
    title: 'account_confirm_password' /*確認密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  {
    key: 'password_confirm',
    title: 'account_device_confirm_password' /*確認授權碼*/,
    forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  // 名稱
  {
    title: 'account_detail_name' /*名稱*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'info.name',
    title: 'account_label' /*帳號名稱*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_name.svg`)
  },
  {
    key: 'video.title',
    title: 'account_video_title' /*影片標題*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_video_title.svg`)
  },
  // 屬性
  {
    title: 'account_detail_property' /*屬性*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true
  },
  {
    key: 'public',
    title: 'account_public_title' /*公開狀態*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: publicOptions,
    icon: require(`@/assets/icons/account_detail_public.svg`)
  },
  {
    key: 'kind',
    title: 'account_type' /*帳號類型*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 從頁面填
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'role',
    title: 'account_role' /*角色*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 重頁面填
    icon: require(`@/assets/icons/account_detail_role.svg`),
    helper: true
  },
  {
    key: '2faMode',
    title: 'account_forced_2fa' /*(強制)雙重驗證*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: forced2faOptions,
    icon: require(`@/assets/icons/account_detail_2fa.svg`),
    helper: false
  },
  {
    key: 'deviceModelId',
    title: 'account_device_model' /*設備類型*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    // sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 重頁面填
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'defaultSetting',
    title: 'account_default_setting' /*參數來源*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    // sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: defaultSettingOptions,
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  {
    key: 'watermarkEnabled',
    title: 'account_watermark' /*浮水印*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: watermarkOptions,
    icon: require(`@/assets/icons/watermark.svg`)
  },
  {
    key: 'language',
    title: 'account_lang' /*語系*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/account_detail_lang.svg`)
  },
  {
    key: 'timezone',
    title: 'account_tz' /*時區*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/account_detail_tz.svg`)
  },
  {
    key: 'kind',
    title: 'account_from' /*帳號來源*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  // 保留天數
  {
    title: 'account_keep_day' /*保留天數*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true
  },
  {
    key: 'video.keepDay',
    title: 'account_video_keep_day' /*影片*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'frKeepDay',
    title: 'account_fr_keep_day' /*人物*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'lprKeepDay',
    title: 'account_lpr_keep_day' /*車牌*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'orKeepDay',
    title: 'account_or_keep_day' /*物件*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/clock-solid.svg`)
  },


  // 勾稽
  {
    title: 'account_detail_linkmode_title' /*勾稽*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true
  },
  {
    key: 'linkedMode',
    title: 'account_linkmode_title' /*勾稽模式*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: linkedModeOptions,
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'linkedUserId',
    title: 'account_linkmode_group' /*群組*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'tree',
    icon: require(`@/assets/icons/Group.svg`)
  },
  {
    key: 'linkedUserId',
    title: 'account_linkmode_user' /*使用者*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: false, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/user.svg`)
  },
  // 最新活動紀錄
  {
    title: 'account_last_action' /*最新活動紀錄*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
    sso: true, // 單簽帳號不能修改的資訊
  },
  {
    key: 'ip',
    title: 'account_ip_source' /*IP來源*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  {
    key: 'lastLogin',
    title: 'account_login_time' /*(登入)時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'gps',
    title: 'account_gps' /*GPS位置*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    // sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/gps.svg`),
    helper: true
  },
  // AUO use info.note to save entry / exit for device.
  // {
  //   key: 'info.note',
  //   title: 'account_note' /*備註*/,
  //   // forUserCreate: false,
  //   // forUser: false,
  //   // forUserMulti: false,
  //   forDeviceCreate: false,
  //   forDevice: false,
  //   // forDeviceMulti: false,
  //   sso: false, // 單簽帳號不能修改的資訊
  //   value: '',
  //   editValue: '',
  //   canEdit: true,
  //   required: false,
  //   ui: 'text',
  //   icon: require(`@/assets/icons/note.svg`)
  // },
  // 時間
  {
    title: 'account_time' /*時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'updatedTime',
    title: 'account_updated_time' /*修改時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'registerTime',
    title: 'account_register_time' /*創建時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    sso: true, // 單簽帳號不能修改的資訊
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  }
]

// AUO
const directionItem = {
  key: 'info.note',
  title: 'account_note_park_direction' /*出入方向*/,
  // forUserCreate: false,
  // forUser: false,
  // forUserMulti: false,
  forDeviceCreate: true,
  forDevice: true,
  // forDeviceMulti: false,
  sso: false, // 單簽帳號不能修改的資訊
  value: '',
  editValue: '',
  canEdit: true,
  required: true,
  ui: 'dropdown',
  icon: require(`@/assets/icons/note.svg`)
}
const vehicleTypeItem = {
  key: 'info.note',
  title: 'account_note_park_vehicle_type' /*車種*/,
  // forUserCreate: false,
  // forUser: false,
  // forUserMulti: false,
  forDeviceCreate: true,
  forDevice: true,
  // forDeviceMulti: false,
  sso: false, // 單簽帳號不能修改的資訊
  value: '',
  editValue: '',
  canEdit: true,
  required: true,
  ui: 'dropdown',
  icon: require(`@/assets/icons/note.svg`)
}
export const parkItems = [directionItem, vehicleTypeItem]

// User
export const createUserItems = accountItems.filter(
  ({ forUserCreate }) => forUserCreate
)
export const userItems = accountItems.filter(({ forUser }) => forUser)
export const multiUserItems = accountItems.filter(
  ({ forUserMulti }) => forUserMulti
)

// Device
export const createDeviceItems = accountItems.filter(
  ({ forDeviceCreate }) => forDeviceCreate
)
export const deviceItems = accountItems.filter(({ forDevice }) => forDevice)
export const multiDeviceItems = accountItems.filter(
  ({ forDeviceMulti }) => forDeviceMulti
)

// 方法==========================================================================

export function deviceModelClass(id) {
  let classify = 0
  // BoviCam Series
  if (id >= 10 && id < 100) {
    classify = 10
  }
  // AiCam Series
  else if (id >= 100 && id < 200) {
    classify = 100
  }
  // Bodyworn Cam Series
  else if (id >= 200 && id < 500) {
    classify = 200
  }
  // FaceArk Series
  else if (id === 500) {
    classify = 500
  }
  // Android APP Series
  else if (id >= 1000 && id < 2000) {
    classify = 1000
  }
  // iOS APP Series
  else if (id >= 2000 && id < 3000) {
    classify = 2000
  }
  // Mac Series
  else if (id === 3000) {
    classify = 3000
  }
  // 其他
  else {
    // classify = 0
  }

  return classify
}

export const genDeviceModelOptions = (deviceModels) => {
  const options = []

  for (let i = 0; i < deviceModels.length; i++) {
    const { id, name, icon } = deviceModels[i]
    let moduleClass = deviceModelClass(id)

    const existClassify = options.find(
      (_o) => _o.classify && _o.value === moduleClass
    )

    if (!existClassify && moduleClass) {
      options.push({
        classify: true,
        label: `account_device_series_${moduleClass}`,
        value: moduleClass,
        // icon,
      })
    }

    const option = {
      classify: false,
      label: name,
      value: id,
      icon: `device_model_${icon}.svg`
    }

    options.push(option)
  }

  return options
}

export function deviceModelFilter(key, options) {
  // console.log(`[deviceModelFilter] key:`, key)
  // console.log(`[deviceModelFilter] options:`, options)
  // 1. 過濾類別
  let classifies = options
    .filter((_o) => _o.classify === true)
    .filter((_c) => {
      return _c.label.toLowerCase().includes(key)
    })
  // console.log(`[deviceModelFilter] classifies:`, classifies)

  // 2. 過濾設備類型
  const models = options
    .filter((_o) => _o.classify !== true)
    .filter((_o) => {
      return _o.label.toLowerCase().includes(key)
    })
  // console.log(`[deviceModelFilter] models:`, models)

  // 3. 重整類別
  const modelClass = models.map((_m) => deviceModelClass(_m.value))
  // console.log(`[deviceModelFilter] modelClass:`, modelClass)
  const fullClass = Array.from(
    // 處理重複
    new Set([...classifies.map((c) => c.value), ...modelClass])
  )
  // console.log(`[deviceModelFilter] fullClass:`, fullClass)

  const classOptions = options.filter(
    (_o) => _o.classify === true && fullClass.includes(_o.value)
  )

  // 4. 以類別為基礎, 將設備類型回填
  let filtered = []
  for (const c of classOptions) {
    filtered.push(c)

    const subModels = models.filter(
      (m) => deviceModelClass(m.value) === c.value
    )

    if (subModels.length > 0) {
      subModels.map((m) => filtered.push(m))
    } else {
      const orgModules = options.filter(
        (o) => o.classify !== true && deviceModelClass(o.value) === c.value
      )
      orgModules.map((m) => filtered.push(m))
    }
  }
  // console.log(`[deviceModelFilter] filtered:`, filtered)

  return filtered
}

export function roleFilter(key, options) {
  return options.filter((_o) => _o.label.toLowerCase().includes(key))
}

export function checkRoleDefaultTopScope(topScope) {
  return topScope === DefaultTopScope
}
